<template>
  <svg class="contact_me_svg" width="821.68" height="447.23" viewBox="0 0 821.68 447.23">
    <g id="Group_1654" data-name="Group 1654" transform="translate(-510 -323)">
      <g id="ground" transform="translate(586.134 566.542)">
        <g id="Group_1294" data-name="Group 1294" transform="translate(11.259 125.202)">
          <g id="Group_1293" data-name="Group 1293">
            <path id="Path_2415" data-name="Path 2415"
              d="M164.047,974.655H895.674c1.029,0,1.029-1.755,0-1.755H164.047c-1.029,0-1.029,1.755,0,1.755Z"
              transform="translate(-163.275 -972.9)" />
          </g>
        </g>
        <g id="Group_1296" data-name="Group 1296" transform="translate(192.443 25.232)">
          <g id="Group_1295" data-name="Group 1295">
            <path id="Path_2416" data-name="Path 2416"
              d="M519.364,901.31A31.113,31.113,0,0,1,505.986,888.5a32.293,32.293,0,0,1-3.358-8.6c-.6-2.984.487-6.027,3.737-5.559,2.112.293,3.791,1.814,5.307,3.218l.975-1.4a22.287,22.287,0,0,1-7.961-10.005c-1.137-2.867-3.791-11.936.758-12.814a.913.913,0,0,0,.487-1.287c-4.333-9.654-5.362-20.42-6.282-30.952-.433-5.032-.867-11.117,2.762-14.979,4.279-4.681,10.182-1.4,13.756,2.575,6.661,7.431,10.128,18.021,13.648,27.441a235.873,235.873,0,0,1,9.207,30.25,137.162,137.162,0,0,1,3.466,36.394.813.813,0,1,0,1.625,0c1.191-25.042-5.795-49.324-14.623-72.143-3.629-9.362-7.745-21.415-16.3-26.8-3.3-2.048-7.311-2.633-10.561-.175-3.3,2.516-4.549,6.963-4.766,11.117-.271,6.378.758,13.106,1.679,19.367a69.472,69.472,0,0,0,5.091,18.782c.162-.41.325-.878.487-1.287-4.766.936-3.791,8.367-2.87,11.936a24.37,24.37,0,0,0,9.694,14.1c.758.527,1.679-.761.975-1.4-2.6-2.34-5.9-4.973-9.424-3.277-3.737,1.814-2.708,6.495-1.625,9.83a33.618,33.618,0,0,0,16.789,19.952c.812.527,1.679-.995.7-1.463Z"
              transform="translate(-497.819 -802.042)" />
          </g>
        </g>
        <g id="Group_1298" data-name="Group 1298" transform="translate(228.69 0)">
          <g id="Group_1297" data-name="Group 1297">
            <path id="Path_2417" data-name="Path 2417"
              d="M567.321,832.514c-1.733-13.984-1.191-27.676,2.87-41.133,1.95-6.495,4.008-12.931,6.012-19.367,1.625-5.207,4.5-14.862,11.373-10.356,4.928,3.218,5.849,11.761,6.986,17.319a174.264,174.264,0,0,1,2.979,20.6,213.82,213.82,0,0,1-.325,39.495,275.194,275.194,0,0,1-8.557,45.58.82.82,0,1,0,1.571.468c7.853-28.846,11.807-59.33,8.557-89.287a176.978,176.978,0,0,0-3.574-21.473c-1.246-5.266-2.545-11.878-7.474-14.569-4.062-2.165-7.961-.059-10.128,3.92a44.3,44.3,0,0,0-2.762,7.372c-1.191,3.862-2.383,7.665-3.575,11.527-2.383,7.723-4.928,15.33-5.9,23.463a110.811,110.811,0,0,0,.379,26.447c.054,1.112,1.679,1.112,1.571,0Z"
              transform="translate(-564.748 -758.918)" />
          </g>
        </g>
        <g id="Group_1300" data-name="Group 1300" transform="translate(198.614 26.876)">
          <g id="Group_1299" data-name="Group 1299">
            <path id="Path_2418" data-name="Path 2418"
              d="M509.314,806.167c15.6,30.016,27.892,62.665,30.708,97.3.108,1.112,1.733,1.112,1.625,0-2.87-34.931-15.164-67.814-30.925-98.181-.487-.995-1.9-.117-1.408.878Z"
              transform="translate(-509.213 -804.852)" />
          </g>
        </g>
        <g id="Group_1302" data-name="Group 1302" transform="translate(242.95 0.846)">
          <g id="Group_1301" data-name="Group 1301">
            <path id="Path_2419" data-name="Path 2419"
              d="M596.56,761.438c1.3,10.239.812,20.6.433,30.894-.379,10.122-.758,20.245-1.137,30.426-.758,20.6-1.246,41.367-4.766,61.67-.163,1.112,1.354,1.58,1.571.468,3.52-20.479,4.062-41.367,4.82-62.138.379-10.532.812-21.064,1.191-31.537.379-10.064.758-20.186-.542-30.191-.108-1.17-1.679-.644-1.571.41Z"
              transform="translate(-591.078 -760.364)" />
          </g>
        </g>
        <g id="Group_1304" data-name="Group 1304" transform="translate(232.745 30.17)">
          <g id="Group_1303" data-name="Group 1303">
            <path id="Path_2420" data-name="Path 2420"
              d="M572.378,811.779a93.81,93.81,0,0,1,14.406,33.468c.217,1.112,1.787.644,1.571-.468a94.928,94.928,0,0,0-14.569-33.936.846.846,0,0,0-1.408.936Z"
              transform="translate(-572.234 -810.481)" />
          </g>
        </g>
        <g id="Group_1306" data-name="Group 1306" transform="translate(248.293 18.872)">
          <g id="Group_1305" data-name="Group 1305">
            <path id="Path_2421" data-name="Path 2421"
              d="M611.372,791.6a138.919,138.919,0,0,0-10.4,27.149.82.82,0,1,0,1.571.468,139.64,139.64,0,0,1,10.236-26.739c.487-.936-.921-1.872-1.408-.878Z"
              transform="translate(-600.942 -791.172)" />
          </g>
        </g>
        <g id="Group_1308" data-name="Group 1308" transform="translate(199.381 74.607)">
          <g id="Group_1307" data-name="Group 1307">
            <path id="Path_2422" data-name="Path 2422"
              d="M511.155,888.167a56.1,56.1,0,0,1,26.917,25.862c.487.995,1.9.117,1.408-.878a57.933,57.933,0,0,0-27.892-26.622c-.975-.527-1.354,1.17-.433,1.638Z"
              transform="translate(-510.629 -886.429)" />
          </g>
        </g>
        <g id="Group_1310" data-name="Group 1310" transform="translate(214.669 36.017)">
          <g id="Group_1309" data-name="Group 1309">
            <path id="Path_2423" data-name="Path 2423"
              d="M539.368,821.309a110.623,110.623,0,0,0,1.191,29.9c.217,1.112,1.787.644,1.571-.468a111.6,111.6,0,0,1-1.137-29.431c.108-1.112-1.516-1.112-1.625,0Z"
              transform="translate(-538.857 -820.475)" />
          </g>
        </g>
        <g id="Group_1312" data-name="Group 1312" transform="translate(17.484 45.639)">
          <g id="Group_1311" data-name="Group 1311">
            <path id="Path_2424" data-name="Path 2424"
              d="M179.281,916.132a61.371,61.371,0,0,1-2.6-12.17c-.162-1.755-.271-3.511-.271-5.325a15.334,15.334,0,0,1,.054-3.16c0-.351.054-.7.054-1.053a1.585,1.585,0,0,1,2.6.293.834.834,0,0,0,1.408-.644,39.944,39.944,0,0,1,.108-7.2c.162-1.17.325-2.34.6-3.452a33.47,33.47,0,0,1,1.516-4.213.781.781,0,0,0,1.462-.234A107.936,107.936,0,0,1,195.8,849.9c1.3-2.34,2.708-4.681,4.116-6.963,1.571-2.516,4.17-6.495,6.553-2.106,2.437,4.447,3.141,10.941,3.358,16.032.542,10.766-1.733,21.473-4.928,31.6-3.087,9.888-6.932,19.777-13.54,27.559-.7.819.433,2.048,1.137,1.229,5.741-6.729,9.369-14.979,12.348-23.463,3.141-9.069,5.687-18.489,6.445-28.144a64.653,64.653,0,0,0-.325-14.1c-.542-4.037-1.354-9.186-3.683-12.58-4.82-6.9-10.4,5.676-12.4,9.186a110.025,110.025,0,0,0-12.24,30.367,12.659,12.659,0,0,0,1.462-.234c-1.354-1.931-3.033,0-3.629,1.638a32.813,32.813,0,0,0-1.625,14.1c.487-.234.921-.41,1.408-.644-1.029-.995-2.708-2.282-4.17-1.521-1.516.819-1.3,3.043-1.3,4.564a61.415,61.415,0,0,0,2.925,20.069.807.807,0,1,0,1.571-.351Z"
              transform="translate(-174.769 -836.92)" />
          </g>
        </g>
        <g id="Group_1314" data-name="Group 1314" transform="translate(25.294 46.919)">
          <g id="Group_1313" data-name="Group 1313">
            <path id="Path_2425" data-name="Path 2425"
              d="M210.913,839.747a218.606,218.606,0,0,1-9.315,40.489c-4.062,12.287-10.886,24.165-12.4,37.271-.108,1.112,1.516,1.112,1.625,0,.812-6.963,3.3-13.4,5.9-19.718,2.437-5.968,4.766-11.995,6.77-18.138a222.076,222.076,0,0,0,8.936-39.495c.162-1.053-1.408-1.521-1.516-.41Z"
              transform="translate(-189.19 -839.107)" />
          </g>
        </g>
        <g id="Group_1316" data-name="Group 1316" transform="translate(32.068 67.591)">
          <g id="Group_1315" data-name="Group 1315">
            <path id="Path_2426" data-name="Path 2426"
              d="M201.7,875.293c.6,9.3,1.462,18.548,2.6,27.793.162,1.112,1.679.643,1.571-.468-1.137-9.069-2-18.2-2.545-27.324a.814.814,0,1,0-1.625,0Z"
              transform="translate(-201.698 -874.437)" />
          </g>
        </g>
        <g id="Group_1318" data-name="Group 1318" transform="translate(40.81 62.158)">
          <g id="Group_1317" data-name="Group 1317">
            <path id="Path_2427" data-name="Path 2427"
              d="M219.226,881.914a78.7,78.7,0,0,0,11.211-15.447c.542-.995-.867-1.872-1.408-.878a79.071,79.071,0,0,1-10.94,15.1c-.758.761.379,1.989,1.137,1.229Z"
              transform="translate(-217.838 -865.152)" />
          </g>
        </g>
        <g id="Group_1320" data-name="Group 1320" transform="translate(0 38.774)">
          <g id="Group_1319" data-name="Group 1319">
            <path id="Path_2428" data-name="Path 2428"
              d="M158.47,910.386a91.594,91.594,0,0,1-9.261-18.606,88.994,88.994,0,0,1-2.762-9.186c-.379-1.638-.758-3.277-1.083-4.973-.217-.995-1.625-7.489.325-7.08a.826.826,0,0,0,.975-1.053,184.248,184.248,0,0,1-2.491-24.4,48.567,48.567,0,0,1,.6-11.234,14.028,14.028,0,0,1,2-5.266c2.491-3.277,5.091-.585,6.716,1.872,7.8,11.819,12.781,26.037,15.164,40.255.162,1.112,1.733.644,1.571-.468a117.069,117.069,0,0,0-7.42-25.511,103.228,103.228,0,0,0-6.066-12.112c-1.516-2.575-3.466-6.612-6.553-7.314-6.77-1.521-7.636,11.234-7.691,15.739a162.8,162.8,0,0,0,2.6,29.021l.975-1.053c-6.5-1.229-1.137,14.862-.325,17.612a94.635,94.635,0,0,0,11.265,24.809c.65.761,2.058-.117,1.462-1.053Z"
              transform="translate(-142.486 -825.186)" />
          </g>
        </g>
        <g id="Group_1322" data-name="Group 1322" transform="translate(5.271 39.651)">
          <g id="Group_1321" data-name="Group 1321">
            <path id="Path_2429" data-name="Path 2429"
              d="M152.263,827.747c6.066,20.3,11.211,40.957,15.435,61.787.217,1.112,1.787.644,1.571-.468-4.224-20.83-9.369-41.484-15.435-61.787a.819.819,0,1,0-1.571.468Z"
              transform="translate(-152.219 -826.685)" />
          </g>
        </g>
        <g id="Group_1324" data-name="Group 1324" transform="translate(0.921 66.271)">
          <g id="Group_1323" data-name="Group 1323">
            <path id="Path_2430" data-name="Path 2430"
              d="M144.628,873.772a31.914,31.914,0,0,1,15.056,14.686c.487.995,1.9.117,1.408-.878a33.226,33.226,0,0,0-15.652-15.33c-.975-.41-1.787,1.112-.812,1.521Z"
              transform="translate(-144.187 -872.182)" />
          </g>
        </g>
        <g id="Group_1326" data-name="Group 1326" transform="translate(13.871 52.306)">
          <g id="Group_1325" data-name="Group 1325">
            <path id="Path_2431" data-name="Path 2431"
              d="M170.7,848.949a96.493,96.493,0,0,0-2.6,17.612.813.813,0,1,0,1.625,0,87.22,87.22,0,0,1,2.545-17.143.82.82,0,1,0-1.571-.468Z"
              transform="translate(-168.098 -848.314)" />
          </g>
        </g>
        <g id="Group_1328" data-name="Group 1328" transform="translate(665.74 32.726)">
          <g id="Group_1327" data-name="Group 1327">
            <path id="Path_2432" data-name="Path 2432"
              d="M1377.887,908.2a66.232,66.232,0,0,1-3.358-35.4,9.439,9.439,0,0,0-.758.644c1.246-.234,2.166.351,2.816,1.814.271.819,1.679.819,1.571-.234a74.2,74.2,0,0,1,.108-15.505,71.936,71.936,0,0,1,1.354-7.607c.271-1.17.6-2.34.92-3.51.217-.644.379-1.346.6-1.989a7.894,7.894,0,0,0,.6-2.984c.433.292.921.585,1.354.878a54.036,54.036,0,0,1,13.81-27.968h-1.137c2.491,2.867,2.6,6.963,2.979,10.649q.569,5.793.812,11.585a226.768,226.768,0,0,1,0,22.7,240.364,240.364,0,0,1-6.607,46.165.82.82,0,1,0,1.571.468,246.942,246.942,0,0,0,6.716-48.856,237.891,237.891,0,0,0-.379-24.867c-.379-5.91.054-14.394-4.008-19.074a.745.745,0,0,0-1.137,0,55.544,55.544,0,0,0-14.244,28.729c-.162.819.758,1.521,1.354.878l.271-.292a.857.857,0,0,0,.217-.878c-.433-1.346-1.571-1.755-2.546-.644a8.643,8.643,0,0,0-1.3,3.1c-.65,1.989-1.191,3.979-1.679,6.027a71.67,71.67,0,0,0-1.408,23.053c.542-.059,1.083-.175,1.571-.234-.758-2.048-2.275-3.277-4.387-3.1a.766.766,0,0,0-.758.643,68.587,68.587,0,0,0,3.358,36.335c.6.936,2.167.527,1.733-.527Z"
              transform="translate(-1371.729 -814.85)" />
          </g>
        </g>
        <g id="Group_1330" data-name="Group 1330" transform="translate(694.477 40.163)">
          <g id="Group_1329" data-name="Group 1329">
            <path id="Path_2433" data-name="Path 2433"
              d="M1426.366,866.259a62.982,62.982,0,0,1,11.319-28.67,55.474,55.474,0,0,1,4.658-5.558c1.083-1.112,3.52-4.271,5.2-1.638.65.995.6,2.633.6,3.8a131.005,131.005,0,0,1-1.571,17.144,171.865,171.865,0,0,1-21.23,61.9c-.542.936.867,1.872,1.408.878a175.115,175.115,0,0,0,20.418-56.287c.866-4.8,1.517-9.6,1.95-14.452.379-3.92,1.516-9.245.162-13.048-2.87-8.191-11.373,4.1-13.431,6.963a63.426,63.426,0,0,0-11.049,28.495c-.108,1.112,1.463,1.58,1.571.468Z"
              transform="translate(-1424.79 -827.561)" />
          </g>
        </g>
        <g id="Group_1332" data-name="Group 1332" transform="translate(691.375 73.64)">
          <g id="Group_1331" data-name="Group 1331">
            <path id="Path_2434" data-name="Path 2434"
              d="M1422.82,885.609a243.013,243.013,0,0,1-3.737,29.372c-.217,1.112,1.354,1.58,1.571.468a238.628,238.628,0,0,0,3.791-29.84.813.813,0,1,0-1.625,0Z"
              transform="translate(-1419.062 -884.775)" />
          </g>
        </g>
        <g id="Group_1334" data-name="Group 1334" transform="translate(689.293 40.88)">
          <g id="Group_1333" data-name="Group 1333">
            <path id="Path_2435" data-name="Path 2435"
              d="M1441.963,829.379q-13.811,41.864-26.7,84.021a.819.819,0,1,0,1.571.468q12.836-42.215,26.7-84.021a.819.819,0,1,0-1.571-.468Z"
              transform="translate(-1415.219 -828.785)" />
          </g>
        </g>
        <g id="Group_1336" data-name="Group 1336" transform="translate(676.761 34.259)">
          <g id="Group_1335" data-name="Group 1335">
            <path id="Path_2436" data-name="Path 2436"
              d="M1404.817,818.131q-6.337,45.638-12.727,91.218c-.163,1.112,1.408,1.58,1.57.468q6.337-45.638,12.727-91.218c.163-1.112-1.408-1.638-1.57-.468Z"
              transform="translate(-1392.078 -817.47)" />
          </g>
        </g>
        <g id="Group_1338" data-name="Group 1338" transform="translate(675.298 59.854)">
          <g id="Group_1337" data-name="Group 1337">
            <path id="Path_2437" data-name="Path 2437"
              d="M1389.39,862.317c1.787,11.819,3.574,23.58,5.362,35.4.162,1.112,1.733.644,1.571-.468-1.787-11.819-3.574-23.58-5.362-35.4-.163-1.112-1.733-.644-1.571.468Z"
              transform="translate(-1389.378 -861.214)" />
          </g>
        </g>
        <g id="Group_1340" data-name="Group 1340" transform="translate(684.242 51.511)">
          <g id="Group_1339" data-name="Group 1339">
            <path id="Path_2438" data-name="Path 2438"
              d="M1415.211,847.391a142,142,0,0,0-9.261,20.888c-.379,1.053,1.192,1.521,1.571.468a135.945,135.945,0,0,1,9.1-20.42c.542-1.053-.866-1.931-1.408-.936Z"
              transform="translate(-1405.892 -846.955)" />
          </g>
        </g>
        <g id="Group_1342" data-name="Group 1342" transform="translate(700.385 58.017)">
          <g id="Group_1341" data-name="Group 1341">
            <path id="Path_2439" data-name="Path 2439"
              d="M1435.7,858.909v30.25a.813.813,0,1,0,1.625,0v-30.25a.813.813,0,1,0-1.625,0Z"
              transform="translate(-1435.7 -858.075)" />
          </g>
        </g>
        <g id="Group_1344" data-name="Group 1344" transform="translate(192.062 145.087)">
          <g id="Group_1343" data-name="Group 1343">
            <path id="Path_2440" data-name="Path 2440"
              d="M498.3,1011.224a13.37,13.37,0,0,1,11.915-1.872c3.52,1.17,6.445,2.75,10.236,2.575,3.52-.176,6.932-1.17,10.453-1.4a13.22,13.22,0,0,1,5.632.644c1.841.7,3.141,1.755,5.091,1.872,1.029.059,1.029-1.7,0-1.755-2.22-.117-4.116-1.814-6.282-2.282a17.9,17.9,0,0,0-5.308-.175c-3.791.41-7.528,1.58-11.319,1.346-3.953-.234-7.311-2.808-11.211-3.218a15.025,15.025,0,0,0-10.019,2.75c-.867.585-.054,2.106.812,1.521Z"
              transform="translate(-497.116 -1006.886)" />
          </g>
        </g>
        <g id="Group_1346" data-name="Group 1346" transform="translate(330.157 141.862)">
          <g id="Group_1345" data-name="Group 1345">
            <path id="Path_2441" data-name="Path 2441"
              d="M752.858,1003.964c5.416-.175,10.832-.41,16.193-.585a61.925,61.925,0,0,1,8.07-.175c2.221.175,3.9,1.521,5.957,2.048a10.307,10.307,0,0,0,4.983-.292,43.373,43.373,0,0,1,8.665-1.463,84.632,84.632,0,0,1,15.381.995c1.029.117,1.029-1.638,0-1.755a84.615,84.615,0,0,0-15.381-.995,38.143,38.143,0,0,0-7.257,1.053c-2.87.7-5.091,1.229-7.853-.117-4.008-1.872-8.232-1.229-12.457-1.053-5.416.176-10.832.41-16.193.585-1.137.059-1.137,1.814-.108,1.755Z"
              transform="translate(-752.099 -1001.374)" />
          </g>
        </g>
        <g id="Group_1348" data-name="Group 1348" transform="translate(524.97 198.873)">
          <g id="Group_1347" data-name="Group 1347">
            <path id="Path_2442" data-name="Path 2442"
              d="M1112.4,1103.31c5.47.878,10.832-.234,16.193-1.346a57.675,57.675,0,0,1,10.019-1.521,34.632,34.632,0,0,1,9.749,1.521,22.611,22.611,0,0,0,8.395.644c2.87-.41,5.632-1.229,8.5-1.58,6.228-.819,12.077,2.457,18.306,2.048,1.029-.058,1.029-1.814,0-1.755-5.795.351-11.211-2.574-17.006-2.223a66.674,66.674,0,0,0-8.178,1.463,23.047,23.047,0,0,1-10.561-.526,34.3,34.3,0,0,0-15.977-.527c-6.337,1.17-12.619,3.1-19.064,2.106-.975-.175-1.408,1.521-.379,1.7Z"
              transform="translate(-1111.807 -1098.811)" />
          </g>
        </g>
        <g id="Group_1350" data-name="Group 1350" transform="translate(674.679 142.059)">
          <g id="Group_1349" data-name="Group 1349">
            <path id="Path_2443" data-name="Path 2443"
              d="M1389.245,1006.209c10.832-3.92,22.584-.234,33.741-1.872a40.116,40.116,0,0,1,9.532-.761c2.654.234,5.145,1.346,7.745,1.638,2.708.234,5.362-.585,8.016-.878a32.434,32.434,0,0,1,9.8.526c1.029.234,1.462-1.463.433-1.7a38.878,38.878,0,0,0-8.232-.761c-2.925.117-5.741.995-8.665,1.053-2.762.059-5.362-1.229-8.124-1.58a32.748,32.748,0,0,0-8.936.41,91.29,91.29,0,0,1-18.739.527c-5.8-.293-11.536-.351-17.06,1.7-.921.351-.542,2.048.487,1.7Z"
              transform="translate(-1388.235 -1001.71)" />
          </g>
        </g>
        <g id="Group_1352" data-name="Group 1352" transform="translate(226.972 190.032)">
          <g id="Group_1351" data-name="Group 1351">
            <path id="Path_2444" data-name="Path 2444"
              d="M562.347,1085.455h134.1c1.029,0,1.029-1.755,0-1.755h-134.1c-1.029,0-1.029,1.755,0,1.755Z"
              transform="translate(-561.575 -1083.7)" />
          </g>
        </g>
        <g id="Group_1354" data-name="Group 1354" transform="translate(351.659 199.569)">
          <g id="Group_1353" data-name="Group 1353">
            <path id="Path_2445" data-name="Path 2445"
              d="M792.612,1101.755h68.836c1.029,0,1.029-1.755,0-1.755H792.612a.88.88,0,0,0,0,1.755Z"
              transform="translate(-791.8 -1100)" />
          </g>
        </g>
        <g id="Group_1356" data-name="Group 1356" transform="translate(526.361 171.601)">
          <g id="Group_1355" data-name="Group 1355">
            <path id="Path_2446" data-name="Path 2446"
              d="M1115.147,1053.955H1187.5c1.029,0,1.029-1.755,0-1.755h-72.356c-1.029,0-1.029,1.755,0,1.755Z"
              transform="translate(-1114.375 -1052.2)" />
          </g>
        </g>
        <g id="Group_1358" data-name="Group 1358" transform="translate(626.337 174.175)">
          <g id="Group_1357" data-name="Group 1357">
            <path id="Path_2447" data-name="Path 2447"
              d="M1299.747,1058.355h42.948c1.029,0,1.029-1.755,0-1.755h-42.948c-1.029,0-1.029,1.755,0,1.755Z"
              transform="translate(-1298.975 -1056.6)" />
          </g>
        </g>
        <g id="Group_1360" data-name="Group 1360" transform="translate(37.58 156.388)">
          <g id="Group_1359" data-name="Group 1359">
            <path id="Path_2448" data-name="Path 2448"
              d="M212.647,1027.955h76.472c1.029,0,1.029-1.755,0-1.755H212.647c-1.029,0-1.029,1.755,0,1.755Z"
              transform="translate(-211.875 -1026.2)" />
          </g>
        </g>
        <g id="Group_1362" data-name="Group 1362" transform="translate(110.531 150.01)">
          <g id="Group_1361" data-name="Group 1361">
            <path id="Path_2449" data-name="Path 2449"
              d="M347.347,1017.055H389.7c1.029,0,1.029-1.755,0-1.755H347.347c-1.029,0-1.029,1.755,0,1.755Z"
              transform="translate(-346.575 -1015.3)" />
          </g>
        </g>
        <g id="Group_1364" data-name="Group 1364" transform="translate(353.5 164.287)">
          <g id="Group_1363" data-name="Group 1363">
            <path id="Path_2450" data-name="Path 2450"
              d="M796.013,1041.455h55.512c1.029,0,1.029-1.755,0-1.755H796.013a.88.88,0,0,0,0,1.755Z"
              transform="translate(-795.2 -1039.7)" />
          </g>
        </g>
      </g>
      <g id="background" transform="translate(598.809 323)">
        <g id="Group_1366" data-name="Group 1366" transform="translate(578.793 100.537)">
          <g id="Group_1365" data-name="Group 1365">
            <path id="Path_2451" data-name="Path 2451"
              d="M1236.166,520.3c.217-4.505,8.124,0,10.344-.41,2.925-.527,3.575-4.037,6.932-3.277,2.058.468,4.008,1.521,6.174,1.287,1.408-.176,2.437-1.053,3.791-1.463a5.627,5.627,0,0,1,5.795,1.7c.758.761,1.9-.468,1.137-1.229a7.255,7.255,0,0,0-8.395-1.755,8.127,8.127,0,0,1-6.607.117,8.753,8.753,0,0,0-3.845-.643c-1.516.234-2.112,1.053-3.141,2.048-3.087,2.867-5.307.234-8.557-.293-2.491-.41-5.037.936-5.2,3.92-.108,1.17,1.516,1.17,1.571,0Z"
              transform="translate(-1234.59 -514.51)" />
          </g>
        </g>
        <g id="Group_1368" data-name="Group 1368" transform="translate(587.895 90.949)">
          <g id="Group_1367" data-name="Group 1367">
            <path id="Path_2452" data-name="Path 2452"
              d="M1253.132,504.6c.433-3.862,6.716-.585,8.557-.41,2.491.234,3.087-1.287,4.874-2.691,3.3-2.633,5.687.7,8.99.527.921-.058,1.408-.644,2.166-1.112,2.112-1.287,4.17-1.58,6.066.351.758.761,1.9-.468,1.137-1.229a6.121,6.121,0,0,0-7.528-1.112c-1.191.585-.487,1.229-2.545,1.229-1.029,0-2.329-.878-3.358-1.17a6.439,6.439,0,0,0-4.441,0c-2.383,1.053-2.491,3.628-5.849,3.335-1.679-.117-3.141-1.229-4.874-1.346-2.329-.176-4.6.878-4.928,3.569-.054,1.17,1.571,1.17,1.733.059Z"
              transform="translate(-1251.398 -498.124)" />
          </g>
        </g>
        <g id="Group_1370" data-name="Group 1370" transform="translate(74.562 304.479)">
          <g id="Group_1369" data-name="Group 1369">
            <path id="Path_2453" data-name="Path 2453"
              d="M304.775,867.65c2.221-1.755,4.116-2.575,6.716-1.229a12.551,12.551,0,0,0,3.141,1.346c2.383.41,4.549-.878,6.661-1.872,1.95-.936,3.575-1.463,5.633-.293,1.137.644,2.058,1.521,3.358,1.755,2.6.527,6.445-2.165,8.5,0,.758.761,1.9-.468,1.137-1.229-2-2.106-4.441-1.112-6.932-.644a5.29,5.29,0,0,1-4.279-.7,10.549,10.549,0,0,0-2.166-1.346,5.782,5.782,0,0,0-3.3-.234,24.325,24.325,0,0,0-4.224,1.814,6.925,6.925,0,0,1-6.824-.117c-3.3-1.638-5.416-1.112-8.395,1.229-.65.644.163,2.165.975,1.521Z"
              transform="translate(-303.564 -863.066)" />
          </g>
        </g>
        <g id="Group_1372" data-name="Group 1372" transform="translate(90.013 296.575)">
          <g id="Group_1371" data-name="Group 1371">
            <path id="Path_2454" data-name="Path 2454"
              d="M333.5,852.976c3.683-4.447,9.965.7,14.514-.351,1.679-.41,2.762-.995,4.5-.351,1.029.41,1.9,1.17,2.925,1.58a6.818,6.818,0,0,0,5.741-.527c.921-.468.108-1.989-.812-1.521-3.791,1.989-5.47-1.112-8.774-1.7a5.241,5.241,0,0,0-2.816.468,8.737,8.737,0,0,1-5.2.176c-3.791-.878-8.232-2.633-11.319,1.053-.6.761.542,1.989,1.246,1.17Z"
              transform="translate(-332.092 -849.558)" />
          </g>
        </g>
        <g id="Group_1374" data-name="Group 1374" transform="translate(204.527 48.011)">
          <g id="Group_1373" data-name="Group 1373">
            <path id="Path_2455" data-name="Path 2455"
              d="M547.09,436.477c-4.224-3.452-1.408-11.176,4.008-9.888a5.236,5.236,0,0,1,4.116,5.032,5.9,5.9,0,0,1-5.145,5.91c-1.029.059-1.029,1.814,0,1.755a7.5,7.5,0,0,0,6.77-6.9,6.982,6.982,0,0,0-5.307-7.431c-7.311-1.814-10.832,8.543-5.253,13.106.812.644,1.625-.878.812-1.58Z"
              transform="translate(-543.535 -424.74)" />
          </g>
        </g>
        <g id="Group_1376" data-name="Group 1376" transform="translate(41.358 237.473)">
          <g id="Group_1375" data-name="Group 1375">
            <path id="Path_2456" data-name="Path 2456"
              d="M244.987,758.233c-2.708-3.218-.162-8.952,4.008-7.723,5.037,1.463,3.737,9.128-1.3,9.069-1.029,0-1.029,1.755,0,1.755a6.506,6.506,0,0,0,6.228-5.734,6.246,6.246,0,0,0-4.062-6.67c-5.9-2.223-10.019,5.793-6.012,10.532.7.819,1.841-.41,1.137-1.229Z"
              transform="translate(-242.255 -748.548)" fill="#ef7974" />
          </g>
        </g>
        <g id="Group_1378" data-name="Group 1378" transform="translate(312.258 228.689)">
          <g id="Group_1377" data-name="Group 1377">
            <path id="Path_2457" data-name="Path 2457"
              d="M753.581,733.69a11.552,11.552,0,0,0-4.17,6.261c-.758,3.042,1.191,7.314-.7,9.6-1.192,1.463-3.25,2.458-3.791,4.447a3.311,3.311,0,0,0,.054,2.048c.271.995.65,1.17.65,2.223,0,1.755-1.408,2.516-2.762,3.277-.921.527-.108,2.048.812,1.521,1.733-.995,3.954-2.633,3.791-5.032-.108-1.521-1.462-2.457-.7-4.1.487-.995,1.733-1.755,2.437-2.516,1.354-1.346,2.275-2.75,2.058-4.8-.271-2.223-.866-3.92-.325-6.2a9.287,9.287,0,0,1,3.412-5.207c.866-.644.054-2.165-.758-1.521Z"
              transform="translate(-742.452 -733.534)" fill="#ef7974" />
          </g>
        </g>
        <g id="Group_1380" data-name="Group 1380" transform="translate(320.699 234.67)">
          <g id="Group_1379" data-name="Group 1379">
            <path id="Path_2458" data-name="Path 2458"
              d="M768.687,743.782c-4.6-.468-5.145,5.676-4.928,9.245.108,1.989.217,3.979-.921,5.617A11.336,11.336,0,0,0,761,761.1a4.432,4.432,0,0,0,.054,2.516c.487,2.867-.975,4.739-2.762,6.612-.758.819.379,2.048,1.137,1.229,1.408-1.521,3.033-3.277,3.412-5.5a16.574,16.574,0,0,0-.217-2.867c0-1.814,1.029-2.458,1.787-3.745,1.192-1.872,1.137-4.154.975-6.319-.162-2.516-.325-7.84,3.3-7.489,1.083.117,1.029-1.638,0-1.755Z"
              transform="translate(-758.038 -743.757)" fill="#ef7974" />
          </g>
        </g>
        <g id="Group_1382" data-name="Group 1382" transform="translate(334.7)">
          <g id="Group_1381" data-name="Group 1381">
            <path id="Path_2459" data-name="Path 2459"
              d="M785.466,361.007c.217-4.037,6.5-2.516,8.828-3.862,1.679-.995,2.654-2.809,3.574-4.505a19.965,19.965,0,0,1,1.733-2.984c1.408-1.755,3.033-1.521,4.983-1.7a8.462,8.462,0,0,0,6.174-3.745c.65-.878-.487-2.106-1.137-1.229a7.418,7.418,0,0,1-5.849,3.277,7.9,7.9,0,0,0-3.466.585c-2.058,1.112-3.033,3.511-4.116,5.558-.866,1.58-1.679,2.867-3.3,3.511-1.571.644-3.358.293-4.983.7a4.816,4.816,0,0,0-4.008,4.447c-.108,1.053,1.516,1.053,1.57-.059Z"
              transform="translate(-783.891 -342.684)" fill="#ef7974" />
          </g>
        </g>
        <g id="Group_1384" data-name="Group 1384" transform="translate(339.479 6.73)">
          <g id="Group_1383" data-name="Group 1383">
            <path id="Path_2460" data-name="Path 2460"
              d="M794.277,373.093c1.625-2.867,4.928-3.569,7.528-4.973a7.762,7.762,0,0,0,4.224-5.5c1.029-4.154,4.008-3.628,7.311-4.154,2-.351,3.629-1.053,4.441-3.218.379-1.053-1.191-1.521-1.571-.468-1.029,2.633-5.091,1.989-7.257,2.516a5.169,5.169,0,0,0-4.387,3.979c-.6,2.165-.812,3.511-2.654,4.739a27.726,27.726,0,0,1-3.683,1.872c-2.166.995-4.17,2.106-5.416,4.33-.487.936.921,1.814,1.462.878Z"
              transform="translate(-792.714 -354.185)" fill="#ef7974" />
          </g>
        </g>
        <g id="Group_1386" data-name="Group 1386" transform="translate(583.257 285.495)">
          <g id="Group_1385" data-name="Group 1385">
            <path id="Path_2461" data-name="Path 2461"
              d="M1244.711,839.21c-1.3-4.33,2.816-8.777,6.716-6.144,4.279,2.867,1.9,9.42-2.87,9.3-1.029-.059-1.029,1.7,0,1.755,6.066.234,9.261-8.133,4.17-12.17-4.983-3.979-11.373,1.638-9.586,7.782a.828.828,0,1,0,1.571-.527Z"
              transform="translate(-1242.833 -830.622)" fill="#ef7974" />
          </g>
        </g>
        <g id="Group_1388" data-name="Group 1388" transform="translate(327.847 343.115)">
          <g id="Group_1387" data-name="Group 1387">
            <path id="Path_2462" data-name="Path 2462"
              d="M773.383,937.818c-1.516-3.043.108-7.489,3.737-6.9a3.278,3.278,0,0,1,2.87,3.16,3.353,3.353,0,0,1-2.87,3.452c-1.029.058-1.029,1.814,0,1.755,5.091-.234,6.012-8.016,1.246-9.83-5.253-1.931-8.719,4.505-6.391,9.245.542.995,1.95.117,1.408-.878Z"
              transform="translate(-771.237 -929.099)" fill="#ef7974" />
          </g>
        </g>
        <g id="Group_1390" data-name="Group 1390" transform="translate(558.033 2.919)">
          <g id="Group_1389" data-name="Group 1389">
            <path id="Path_2463" data-name="Path 2463"
              d="M1198.394,353.072c-1.571-1.872.7-4.505,2.654-3.277,2.437,1.58.975,4.8-1.3,5.441-1.029.293-.6,1.989.433,1.7,3.953-1.053,5.307-7.08,1.083-8.952-3.575-1.58-6.607,3.277-4.062,6.319.758.878,1.9-.351,1.192-1.229Z"
              transform="translate(-1196.259 -347.673)" fill="#ef7974" />
          </g>
        </g>
        <g id="Group_1392" data-name="Group 1392" transform="translate(0 60.089)">
          <g id="Group_1391" data-name="Group 1391">
            <path id="Path_2464" data-name="Path 2464"
              d="M169.145,453.35c-3.141-1.755-1.246-7.138,2.275-6.027,4.116,1.287,2.708,7.021-.921,7.84-1.029.234-.6,1.931.433,1.7,5.416-1.287,6.445-9.947.487-11.351-5.145-1.229-7.745,6.729-3.141,9.3a.85.85,0,0,0,.867-1.463Z"
              transform="translate(-165.89 -445.381)" />
          </g>
        </g>
      </g>
      <path id="Path_2483" data-name="Path 2483"
        d="M800.476,541.1a33.953,33.953,0,0,0-9.64,14.394c1.625.819,5.091,2.867,5.795,6.495.812,3.979-2.221,7.606-6.228,12.287a56.73,56.73,0,0,1-12.673,10.883,10.732,10.732,0,0,1-5.362-1.4,11.871,11.871,0,0,1-4.5-4.856,34.2,34.2,0,0,0-8.395,3.92,36.336,36.336,0,0,0-6.878,5.793c.758,1.872,3.033,7.021,8.178,9.245,2,.878,4.549,1.346,9.261.468,4.766-.878,11.211-2.984,18.847-8.6A62.97,62.97,0,0,0,806.7,569.536c3.791-6.963,6.5-11.936,4.928-17.612C809.629,544.845,802.155,541.744,800.476,541.1Z"
        transform="translate(163.962 -102.005)" />
      <g id="Group_1428" data-name="Group 1428" transform="translate(930.368 452.957)">
        <g id="Group_1427" data-name="Group 1427">
          <path id="Path_2484" data-name="Path 2484"
            d="M779.666,576.323a26.127,26.127,0,0,1,11.59-9.771c.975-.41.542-2.106-.433-1.7a28.056,28.056,0,0,0-12.565,10.59c-.65.936.758,1.814,1.408.878Z"
            transform="translate(-778.091 -564.792)" />
        </g>
      </g>
      <g id="Group_1430" data-name="Group 1430" transform="translate(921.515 443.098)">
        <g id="Group_1429" data-name="Group 1429">
          <path id="Path_2485" data-name="Path 2485"
            d="M763.237,570.386a35.328,35.328,0,0,1,23.18-20.713c1.029-.293.6-1.989-.433-1.7-10.615,2.809-19.551,10.766-24.155,21.473-.433,1.112.921,1.989,1.408.936Z"
            transform="translate(-761.744 -547.942)" />
        </g>
      </g>
      <g id="Group_1432" data-name="Group 1432" transform="translate(913.897 435.511)">
        <g id="Group_1431" data-name="Group 1431">
          <path id="Path_2486" data-name="Path 2486"
            d="M749.261,566.878a35.057,35.057,0,0,1,11.1-20.479c5.524-5.09,14.027-10.59,21.555-9.537,1.029.117,1.029-1.638,0-1.755-7.691-1.053-16.248,4.388-22.043,9.42a36.733,36.733,0,0,0-12.186,21.824c-.162,1.17,1.408,1.638,1.571.527Z"
            transform="translate(-747.678 -534.974)" fill="#161616" />
        </g>
      </g>
      <g id="boy" transform="translate(941.473 360.135)">
        <g id="leg_right" transform="translate(34.058 217.021)">
          <path id="Path_2487" data-name="Path 2487"
            d="M893.879,1032.273c.217,7.606.379,15.213.6,22.819q-33.551,10.707-32.983,14.043.731,4.213,53.779-2.809c-.758-12.989-1.516-26.037-2.329-39.026C906.606,1028.938,900.269,1030.577,893.879,1032.273Z"
            transform="translate(-861.482 -880.883)" fill="#6d5856" stroke="#6d5856" stroke-miterlimit="10"
            stroke-width="2" />
          <path id="Path_2488" data-name="Path 2488"
            d="M979.508,778.488c-3.25,1.053-2.546,1.463-20.093,16.032-18.576,15.388-27.838,23.112-34.282,27.032-3.737,2.282-6.77,3.92-10.507,7.665-6.174,6.261-9.315,13.4-11.752,20.362-7.582,21.707-8.72,42.479-8.936,51.723a224.8,224.8,0,0,0,1.841,35.048,60.337,60.337,0,0,0,14.19,1.989c6.012.117,14.569.234,23.451-4.681a43.653,43.653,0,0,0,5.849-3.979,188.436,188.436,0,0,1-2.166-37.037,192.173,192.173,0,0,1,3.683-30.367,102.791,102.791,0,0,1,12.944-9.011c6.553-3.8,13.106-6.612,31.2-12.346,21.664-6.846,22.422-6.085,25.617-9.011,8.286-7.489,14.244-22.585,9.586-35.691C1014.061,778.956,992.723,774.334,979.508,778.488Z"
            transform="translate(-876.327 -777.061)" fill="#fff" />
          <g id="Group_1434" data-name="Group 1434" transform="translate(16.488 7.181)">
            <g id="Group_1433" data-name="Group 1433">
              <path id="Path_2489" data-name="Path 2489"
                d="M970.28,789.49c-10.019,7.548-19.985,15.154-30,22.7-8.882,6.729-18.414,13.048-26.538,20.947-7.8,7.548-12.132,16.968-14.948,27.734a219.554,219.554,0,0,0-6.12,36.628,224.463,224.463,0,0,0,.6,42.654c.108,1.112,1.733,1.112,1.625,0a221.637,221.637,0,0,1,3.954-72.495c2.545-11.41,5.9-22.41,13.756-30.894,7.582-8.191,17.114-14.394,25.834-21.005l32.658-24.75c.867-.644.054-2.165-.812-1.521Z"
                transform="translate(-891.927 -789.334)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1436" data-name="Group 1436" transform="translate(64.553 56.92)">
            <g id="Group_1435" data-name="Group 1435">
              <path id="Path_2490" data-name="Path 2490"
                d="M1041.671,874.376c-11.915,3.686-23.667,7.782-35.257,12.463-5.091,2.048-10.29,4.1-15.273,6.495-4.116,1.989-7.961,4.505-10.344,8.777-.542.995.867,1.872,1.408.878,2.6-4.739,7.257-7.08,11.752-9.069,5.2-2.282,10.453-4.447,15.76-6.553q16-6.319,32.333-11.293c1.029-.292.6-1.989-.379-1.7Z"
                transform="translate(-980.675 -874.342)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1438" data-name="Group 1438" transform="translate(60.223 75.276)">
            <g id="Group_1437" data-name="Group 1437">
              <path id="Path_2491" data-name="Path 2491"
                d="M977.836,906.349a209.79,209.79,0,0,0-3.033,76.005c.162,1.112,1.733.644,1.571-.468a207.032,207.032,0,0,1,3.033-75.069c.217-1.112-1.354-1.58-1.571-.468Z"
                transform="translate(-972.679 -905.714)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1440" data-name="Group 1440" transform="translate(18.527 151.59)">
            <g id="Group_1439" data-name="Group 1439">
              <path id="Path_2492" data-name="Path 2492"
                d="M895.7,1042.495c.271,3.277,4.983,2.75,7.041,2.984a91.734,91.734,0,0,0,15,.644c8.124-.468,16.085-2.926,22.476-8.543.812-.7-.325-1.931-1.137-1.229a33.731,33.731,0,0,1-19.172,7.84,65.621,65.621,0,0,1-10.723.117c-1.3-.058-11.752-.351-11.861-1.814-.108-1.112-1.733-1.112-1.625,0Z"
                transform="translate(-895.69 -1036.142)" fill="#161616" />
            </g>
          </g>
          <path id="Path_2493" data-name="Path 2493"
            d="M1022.207,840.055c-7.474-4.1-16.031,6.378-37.153,16.793-17.277,8.543-21.555,6.436-28.325,10.883-11.915,7.782-22.476,29.957-14.893,94.378a27.5,27.5,0,0,0,5.795-.643,28.806,28.806,0,0,0,12.186-6.261,192.508,192.508,0,0,1-1.192-47.394,182.872,182.872,0,0,1,3.358-21.356,164.978,164.978,0,0,1,23.451-12.17c24.967-10.59,40.456-9.947,42.948-19.426C1029.68,849.534,1026.648,842.454,1022.207,840.055Z"
            transform="translate(-897.145 -802.82)" fill="#666" opacity="0.11" />
          <path id="Path_2494" data-name="Path 2494"
            d="M1022.931,877.313c-.271-1.58-17.818-.761-36.232,5.91-11.752,4.213-18.143,6.553-23.721,13.165C954.1,906.8,953.608,920.2,952.9,938.807a165.8,165.8,0,0,0,2.762,36.862c3.574-1.229,7.095-2.4,10.669-3.628a225.946,225.946,0,0,1,1.841-68.4,46.355,46.355,0,0,1,7.312-6.9c7.311-5.558,13.756-6.963,21.338-9.186C1006.629,884.568,1023.2,878.834,1022.931,877.313Z"
            transform="translate(-903.339 -818.369)" fill="#666" opacity="0.12" />
        </g>
        <g id="leg_left" transform="translate(148.025 210.054)">
          <path id="Path_2495" data-name="Path 2495"
            d="M1250,961.147a202.586,202.586,0,0,1,29.137-7.9c10.453-1.99,12.89-1.58,14.839,0,3.358,2.691,2.654,6.67,3.9,31.479.433,8.133.65,10.181-.866,12.7-5.145,8.835-22.259,8.425-23.559,4.564-.488-1.521,1.137-4.33,9.261-10.005-.271-5.559-.542-11.117-.866-16.676-9.261,2.4-18.522,4.856-27.729,7.255C1252.816,975.482,1251.408,968.343,1250,961.147Z"
            transform="translate(-1153.551 -842.635)" fill="#6d5856" stroke="#6d5856" stroke-miterlimit="10"
            stroke-width="2" />
          <path id="Path_2496" data-name="Path 2496"
            d="M1073.748,782.909c-3.574,9.069-1.029,18.782.163,23.346,1.354,5.266,2.654,5.968,4.82,13.048,2.329,7.606,3.033,13.106,4.008,19.718,1.408,9.654,3.033,20.654,5.578,31.654,3.141,13.574,4.874,20.537,10.073,28.085,2.275,3.335,7.636,10.824,17.06,14.8,1.787.761,4.17,1.58,10.073,2.4a100.885,100.885,0,0,0,57.841-7.255,41.734,41.734,0,0,0,1.137-13.282c-.325-3.745-.866-10.707-5.578-16.617a15.868,15.868,0,0,0-6.716-5.441c-3.683-1.346-6.986-.468-12.836,1.229-6.77,1.931-9.261,4.037-13.7,3.043a11.927,11.927,0,0,1-4.17-1.814,66.98,66.98,0,0,1-4.766-9.654c-4.387-11-4.712-19.835-5.307-29.548-1.354-21.883-2.058-32.824-5.308-44.936-3.412-12.7-4.387-19.074-9.478-22.936-5.253-3.979-11.211-3.686-14.894-3.511C1092.7,765.648,1078.73,770.329,1073.748,782.909Z"
            transform="translate(-1071.914 -765.153)" fill="#fff" />
          <g id="Group_1442" data-name="Group 1442" transform="translate(47.245 6.262)">
            <g id="Group_1441" data-name="Group 1441">
              <path id="Path_2497" data-name="Path 2497"
                d="M1159.231,777.227c8.882,20.128,9.586,42.713,11.536,64.479,1.408,15.74,2.654,37.33,17.439,45.638.921.527,1.733-.995.812-1.521-7.582-4.271-11.373-12.7-13.485-21.3-2.275-9.42-2.871-19.308-3.629-29.021-1.625-20.128-3.087-40.665-11.211-59.213-.487-.995-1.9-.117-1.462.936Z"
                transform="translate(-1159.149 -775.855)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1444" data-name="Group 1444" transform="translate(3.214 46.379)">
            <g id="Group_1443" data-name="Group 1443">
              <path id="Path_2498" data-name="Path 2498"
                d="M1077.93,845.753c9.532,23.346,7.149,49.968,16.41,73.372,3.683,9.362,9.207,18.138,17.06,23.931,9.532,6.963,21.934,8.367,33.145,8.191,14.135-.234,28.054-3.745,41.16-9.3.975-.41.542-2.106-.433-1.7a110.54,110.54,0,0,1-35.745,9.011c-11.7.819-25.021.176-35.365-6.378-18.143-11.527-22.963-36.277-25.888-56.931-1.95-13.984-3.683-28.085-9.044-41.133-.325-.936-1.733-.059-1.3.936Z"
                transform="translate(-1077.848 -844.419)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1446" data-name="Group 1446" transform="translate(68.152 106.484)">
            <g id="Group_1445" data-name="Group 1445">
              <path id="Path_2499" data-name="Path 2499"
                d="M1198.157,951.623c9.8,6.2,21.013-5.734,31.358-2.106,5.741,2.048,8.557,8.718,10.128,14.569a43.332,43.332,0,0,1,.758,19.367c-.217,1.112,1.354,1.58,1.571.468a47.659,47.659,0,0,0-.271-18.372c-1.246-5.5-3.412-11.76-7.691-15.388-3.953-3.394-9.207-3.452-13.973-2.458-6.391,1.346-14.785,6.436-21.068,2.458-.921-.585-1.733.878-.812,1.463Z"
                transform="translate(-1197.752 -947.143)" fill="#161616" />
            </g>
          </g>
          <path id="Path_2500" data-name="Path 2500"
            d="M1142.36,775.033c-9.1,9.654,9.261,30.894,9.586,66.585.271,27.91-5.849,39.495,2.383,48.856,11.807,13.4,38.507,5.676,41.431,4.8,4.657-1.4,14.623-3.452,15.435-8.894.488-3.569-2.979-7.606-6.5-9.6-10.723-6.144-20.743,7.431-30.166,2.984-7.095-3.393-8.665-14.628-11.644-35.516-2.437-17.144-.921-20.771-3.791-39.612-2.112-13.867-4.82-31.186-11.319-32.181C1145.176,771.991,1142.793,774.507,1142.36,775.033Z"
            transform="translate(-1103.048 -768.16)" fill="#666" opacity="0.11" />
          <path id="Path_2501" data-name="Path 2501"
            d="M1170.809,816.1c-.866.058-.921,11.292-1.029,25.159-.271,39.378,1.787,47.745,6.5,55.117,1.949,3.043,5.632,8.66,12.348,11.819,14.839,7.08,34.553-2.223,34.607-8.133,0-2.984-5.037-5.207-5.849-5.558-5.145-2.223-9.1-.059-19.551,1.872-4.333.819-8.015,1.229-10.615,1.463a53.3,53.3,0,0,1-6.824-12.229c-4.17-10.356-3.845-18.782-4.116-27.734C1175.683,835.759,1171.892,816.041,1170.809,816.1Z"
            transform="translate(-1116.766 -786.29)" fill="#666" opacity="0.17" />
        </g>
        <g id="hand_reight" transform="translate(0 106.506)">
          <path id="Path_2502" data-name="Path 2502"
            d="M800.1,624.8c.108-1.346,2.979-2.048,4.712-2.457,7.095-1.7,13.215-.41,14.839.293,3.033,1.287,6.066,2.575,9.153,3.862a8.726,8.726,0,0,1,2.221-4.681c2.816-2.75,6.824-1.7,7.907-1.346,1.408.41,3.629,1.346,7.42,5.793,3.9,4.564,4.441,6.9,6.661,9.888,5.145,6.9,12.89,8.718,16.789,9.6,3.412.819,10.29,2.4,18.035-1.112,6.824-3.1,10.669-8.543,12.348-11,1.625-2.4,2.166-3.8,5.416-11.819,6.607-16.324,8.72-21.356,13.323-26.388,2.491-2.75,5.524-5.968,8.665-5.208,3.845.936,5.47,7.431,6.174,10.181.758,3.043,1.733,8.835-.487,18.958a76.309,76.309,0,0,1-10.886,25.277c-3.141,4.8-7.528,11.292-15.327,16.793-3.412,2.4-8.99,6.261-17.06,7.431-7.149,1.053-12.619-.527-17.277-1.931A59.915,59.915,0,0,1,849.976,654c-5.795.293-10.236.7-13.323,1.112-5.253.644-7.474,1.17-9.857-.293a5.782,5.782,0,0,0-3.683-1.346c-1.571.117-2.112,1.053-3.466,1.346a5.3,5.3,0,0,1-5.416-1.931,6.48,6.48,0,0,1-.975-4.213,11.15,11.15,0,0,1-3.087-4.973,15.6,15.6,0,0,1-.433-4.681c.054-1.872.054-2.809.487-3.569,1.246-2.34,4.82-2.223,5.362-2.165a17.557,17.557,0,0,0-4.279-2.575c-3.358-1.463-5.145-.995-8.232-2.808C801.829,627.081,799.988,625.97,800.1,624.8Z"
            transform="translate(-799.282 -588.979)" fill="#fff" />
          <g id="Group_1448" data-name="Group 1448" transform="translate(89.047)">
            <g id="Group_1447" data-name="Group 1447">
              <path id="Path_2503" data-name="Path 2503"
                d="M1002.76,588.66c-4.712-1.931-8.232,2.341-10.723,6.144a115.787,115.787,0,0,0-9.64,18.08c-5.091,12.053-8.557,26.1-19.01,34.17-.867.644-.054,2.165.812,1.521,8.449-6.612,12.673-16.968,16.518-27.09a154.926,154.926,0,0,1,7.853-17.729c1.516-2.75,3.087-5.5,4.82-8.075,2.058-3.1,4.82-6.963,8.882-5.324,1.029.41,1.462-1.287.487-1.7Z"
                transform="translate(-963.016 -588.18)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1450" data-name="Group 1450" transform="translate(29.639 29.924)">
            <g id="Group_1449" data-name="Group 1449">
              <path id="Path_2504" data-name="Path 2504"
                d="M857.752,647.969c-2.329.819-3.25-2.282-2.545-3.979a4.873,4.873,0,0,1,3.141-2.458,9.422,9.422,0,0,1,6.987.878c3.52,1.872,5.524,5.676,7.691,9.069a36.6,36.6,0,0,0,14.948,13.691c12.186,5.793,26.429,2.691,35.8-7.489.758-.819-.433-2.048-1.137-1.229a29.31,29.31,0,0,1-24.534,9.83c-9.315-.936-17.764-6.787-23.18-14.92-4.008-6.144-9.045-14.393-17.331-11.41-2.329.819-4.6,3.043-4.224,5.91.325,2.574,2.491,4.622,4.874,3.745.921-.293.487-1.989-.487-1.638Z"
                transform="translate(-853.323 -639.323)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1452" data-name="Group 1452" transform="translate(0 31.296)">
            <g id="Group_1451" data-name="Group 1451">
              <path id="Path_2505" data-name="Path 2505"
                d="M827.032,646.543a37.642,37.642,0,0,0-13.7-4.681c-4.224-.468-10.128-.293-13.594,2.809-3.141,2.808.975,4.622,3.2,5.617,5.091,2.282,10.832,2.984,15.76,5.734.921.527,1.733-.995.812-1.521-3.033-1.7-6.282-2.516-9.532-3.511a53.082,53.082,0,0,1-5.091-1.814c-.921-.351-2.762-.761-3.466-1.58-1.246-1.4.325-2.282,1.516-2.75,7.528-3.335,16.41-.527,23.342,3.218.867.468,1.679-1.053.758-1.521Z"
                transform="translate(-798.596 -641.667)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1454" data-name="Group 1454" transform="translate(9.289 43.314)">
            <g id="Group_1453" data-name="Group 1453">
              <path id="Path_2506" data-name="Path 2506"
                d="M826.175,663.432a6.763,6.763,0,0,0-7.582-.117c-1.9,1.229-3.466,3.92-2.6,6.319.975,2.75,4.766,3.043,6.986,3.511,3.412.644,6.662.644,9.8-1.053.921-.527.108-2.048-.812-1.521-3.52,1.931-7.42,1.229-11.157.293-1.462-.351-1.516-.293-2.437-1.989-1.191-1.053-1.137-2.165.054-3.218.325-.293.65-.527.975-.819a5.384,5.384,0,0,1,5.957.117c.867.585,1.679-.936.812-1.521Z"
                transform="translate(-815.748 -662.208)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1456" data-name="Group 1456" transform="translate(10.035 51.141)">
            <g id="Group_1455" data-name="Group 1455">
              <path id="Path_2507" data-name="Path 2507"
                d="M817.49,676.179a6.822,6.822,0,0,0,3.087,8.133c3.574,2.048,7.636.527,11.048-.936.975-.41.542-2.106-.433-1.7-2.275.995-4.766,2.048-7.257,1.814-3.25-.292-6.12-3.16-4.874-6.846a.819.819,0,1,0-1.571-.468Z"
                transform="translate(-817.124 -675.585)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1458" data-name="Group 1458" transform="translate(12.727 58.514)">
            <g id="Group_1457" data-name="Group 1457">
              <path id="Path_2508" data-name="Path 2508"
                d="M822.315,688.779c-.975,3.276,1.462,6.026,4.224,6.9,4.062,1.229,7.311-1.229,10.236-3.979.812-.761-.379-1.989-1.137-1.229-1.841,1.7-3.737,3.511-6.228,3.745s-6.607-1.4-5.524-4.973a.819.819,0,1,0-1.571-.468Z"
                transform="translate(-822.096 -688.185)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1460" data-name="Group 1460" transform="translate(21.778 45.895)">
            <g id="Group_1459" data-name="Group 1459">
              <path id="Path_2509" data-name="Path 2509"
                d="M839.4,668.363c1.625.585,4.116,2.165,2.816,4.33-.542.936.867,1.814,1.408.878a4.17,4.17,0,0,0-.108-4.271,6.326,6.326,0,0,0-3.737-2.633c-.975-.351-1.408,1.346-.379,1.7Z"
                transform="translate(-838.807 -666.619)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1462" data-name="Group 1462" transform="translate(23.158 52.082)">
            <g id="Group_1461" data-name="Group 1461">
              <path id="Path_2510" data-name="Path 2510"
                d="M842.9,678.718a2.483,2.483,0,0,1-.975,3.979c-.975.293-.6,1.989.433,1.7,2.87-.878,3.683-4.622,1.733-6.963-.758-.761-1.9.468-1.191,1.287Z"
                transform="translate(-841.357 -677.192)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1464" data-name="Group 1464" transform="translate(25.062 56.307)">
            <g id="Group_1463" data-name="Group 1463">
              <path id="Path_2511" data-name="Path 2511"
                d="M845.266,686.044a2.215,2.215,0,0,1,.379,3.277c-.758.761.379,2.048,1.137,1.229a4.035,4.035,0,0,0-.7-6.027c-.866-.526-1.733.995-.812,1.521Z"
                transform="translate(-844.872 -684.413)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1466" data-name="Group 1466" transform="translate(26.947 35.966)">
            <g id="Group_1465" data-name="Group 1465">
              <path id="Path_2512" data-name="Path 2512"
                d="M848.757,651.259c1.191.585,2.383,1.229,3.574,1.814.921.468,1.787-1.053.812-1.521-1.192-.585-2.383-1.229-3.575-1.814-.921-.468-1.733,1.053-.812,1.521Z"
                transform="translate(-848.352 -649.649)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1468" data-name="Group 1468" transform="translate(33.301 37.211)">
            <g id="Group_1467" data-name="Group 1467">
              <path id="Path_2513" data-name="Path 2513"
                d="M861.5,654.833a4.677,4.677,0,0,1,4.387-1.17c1.029.234,1.462-1.463.433-1.7a6.3,6.3,0,0,0-5.957,1.638.837.837,0,0,0,1.137,1.229Z"
                transform="translate(-860.084 -651.778)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1470" data-name="Group 1470" transform="translate(35.103 41.738)">
            <g id="Group_1469" data-name="Group 1469">
              <path id="Path_2514" data-name="Path 2514"
                d="M863.947,660.149a11.376,11.376,0,0,0,4.333,12.872c.921.585,1.733-.936.812-1.521a9.54,9.54,0,0,1-3.574-10.883.821.821,0,1,0-1.571-.468Z"
                transform="translate(-863.412 -659.514)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1472" data-name="Group 1472" transform="translate(23.84 63.106)">
            <g id="Group_1471" data-name="Group 1471">
              <path id="Path_2515" data-name="Path 2515"
                d="M842.986,697.711a12.213,12.213,0,0,0,10.615,2.223A50.535,50.535,0,0,1,869.578,699c1.029.117,1.029-1.638,0-1.755a43.818,43.818,0,0,0-12.673.234c-4.82.819-8.774,2.048-13.106-1.287-.867-.644-1.679.878-.812,1.521Z"
                transform="translate(-842.616 -696.034)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1474" data-name="Group 1474" transform="translate(48.988 12.811)">
            <g id="Group_1473" data-name="Group 1473">
              <path id="Path_2516" data-name="Path 2516"
                d="M889.456,662.574c7.745,3.979,14.894,9.3,22.909,12.58a36.452,36.452,0,0,0,23.126,1.521c7.907-2.106,14.3-7.138,19.876-13.4a85.91,85.91,0,0,0,14.677-21.824,59.139,59.139,0,0,0,5.362-30.543c-.108-1.112-1.733-1.112-1.625,0a58.6,58.6,0,0,1-6.878,33.176c-4.928,9.362-11.915,18.723-20.093,25.042a34.121,34.121,0,0,1-28.758,6.027c-10.073-2.458-18.576-9.42-27.838-14.16-.866-.409-1.679,1.112-.758,1.58Z"
                transform="translate(-889.049 -610.075)" fill="#161616" />
            </g>
          </g>
          <path id="Path_2517" data-name="Path 2517"
            d="M939.776,600.6c-2.112.117-1.841,9.83-4.387,19.777-3.737,14.394-14.46,33.293-30.22,39.085-11.157,4.1-22.422.7-40.348-4.739-5.687-1.7-15.706-5.266-29.57-6.2-11.427-.761-14.244.995-14.352,1.931-.054.585.975.995,1.571,2.867.487,1.58-.054,2.106.433,3.1.867,1.814,3.466,2.048,4.387,2.165a8.794,8.794,0,0,0,5.091-1.17,20.762,20.762,0,0,0,4.658,1.7c3.9.878,6.12.059,11.048-.468,5.091-.527,8.449-.878,12.131.234,3.52,1.053,5.145,2.809,8.178,5.032.921.644,9.586,6.9,20.309,8.835a34.175,34.175,0,0,0,19.605-1.7c4.874-1.989,7.961-4.739,11.482-7.84,2.22-1.989,12.132-11.117,19.389-28.144,3.2-7.431,5.795-13.692,5.091-21.941C943.676,607.094,941.563,600.482,939.776,600.6Z"
            transform="translate(-808.819 -593.332)" fill="#666" opacity="0.16" />
          <path id="Path_2518" data-name="Path 2518"
            d="M846.736,688.326c2.6-1.4,5.633-.234,6.824.234,6.77,2.516,7.42.761,17.656,2.4,8.449,1.346,8.774,3.979,19.876,7.84,10.669,3.745,19.28,6.729,27.783,3.8,12.077-4.154,17.656-17.319,18.089-16.91.433.351-4.441,13.106-16.085,19.075-10.561,5.441-21.555,2.691-24.7,1.931-5.091-1.287-9.207-3.394-13.7-5.734a99.683,99.683,0,0,1-12.348-7.606,45.715,45.715,0,0,0-8.828.468c-5.037.819-7.149,2.048-11.265,1.4-1.083-.176-5.578-.878-5.957-3.1C843.865,690.667,845.328,689.087,846.736,688.326Z"
            transform="translate(-819.438 -628.634)" fill="#666" opacity="0.17" />
        </g>
        <g id="torso" transform="translate(117.379 98.229)">
          <path id="Path_2519" data-name="Path 2519"
            d="M1021.054,585.014c-1.462,1.931-3.358,5.149-4.766,30.718a436.081,436.081,0,0,0-.379,50.846c.487,8.894,1.408,21.064,3.3,35.457a69.11,69.11,0,0,0,31.737,10.239c23.451,1.521,40.565-9.947,46.306-14.16a127.213,127.213,0,0,1-9.478-28.378c-3.141-15.213-1.625-21.942-3.3-40.606-2.221-24.691-3.3-37.037-10.236-44.527C1060.969,570.211,1031.832,570.679,1021.054,585.014Z"
            transform="translate(-1015.329 -574.033)" />
          <path id="Path_2520" data-name="Path 2520"
            d="M1080.4,574.8a126.233,126.233,0,0,1,12.673,27.324c1.679,5.207,10.019,32.005,3.954,66.468a142.32,142.32,0,0,1-15.381,43.941,68.567,68.567,0,0,0,14.406-.527,70.123,70.123,0,0,0,31.25-13.165,106.839,106.839,0,0,1-6.716-16.617,122.053,122.053,0,0,1-3.954-19.016c-.975-7.255-.812-9.654-1.462-21.473-.162-2.75-.812-13.926-2.22-26.8-2.058-18.84-3.629-22.936-6.228-26.564a32.98,32.98,0,0,0-10.4-9.128A35.26,35.26,0,0,0,1080.4,574.8Z"
            transform="translate(-1045.158 -574.351)" fill="#666" opacity="0.22" />
          <path id="Path_2521" data-name="Path 2521"
            d="M1128.223,649.9c-.812,9.479-1.9,19.25-3.2,29.314-2.979,22.7-6.932,43.824-11.427,63.191a55.008,55.008,0,0,0,13.54-3.745,57.207,57.207,0,0,0,15.164-9.479,93.19,93.19,0,0,1-10.615-33.351c-.921-8.25-.6-16.207-2.383-35.633C1128.873,655.868,1128.493,652.3,1128.223,649.9Z"
            transform="translate(-1060.378 -605.51)" fill="#666" opacity="0.22" />
        </g>
        <g id="head" transform="translate(96.41)">
          <path id="Path_2522" data-name="Path 2522"
            d="M984.229,463.076c-1.083,2.867-1.029,6.261-1.029,13.106a66.914,66.914,0,0,0,2.383,19.66c1.787,6.2,3.52,12.4,8.882,17.9,1.516,1.58,8.07,8.309,17.331,8.309a18.942,18.942,0,0,0,4.279-.468,20.976,20.976,0,0,0,3.087,6.729,20.428,20.428,0,0,0,2.654,3.042,14.381,14.381,0,0,0,8.015-2.574,15.56,15.56,0,0,0,5.308-6.553,13.771,13.771,0,0,1-3.683-8.66,14.468,14.468,0,0,1,.271-3.218,46.974,46.974,0,0,0,5.308-9.6c.812-1.931,1.408-3.745,1.95-5.441a5.473,5.473,0,0,0,4.008-.293c1.679-.936,2.275-2.75,2.979-4.973,1.029-3.277,2.167-6.963.758-10.239-1.354-3.1-3.466-2.4-5.307-5.266-2.816-4.271-.054-8.6-1.192-13.282-1.733-7.2-11.373-10.3-15.11-11.527C1009.791,444.82,988.995,450.32,984.229,463.076Z"
            transform="translate(-979.631 -423.494)" fill="#fff" />
          <path id="Path_2523" data-name="Path 2523"
            d="M980.064,454.633c1.354.234,2.22-2.048,4.333-4.33,2.166-2.282,5.795-4.681,12.294-5.324,5.308-.585,10.615-1.229,15.923-1.814a17.615,17.615,0,0,1,9.369,3.452,20.241,20.241,0,0,1,5.578,6.67c1.679.059,3.3.176,4.983.234.325,2.165.7,4.33,1.029,6.495.217-.234,3.141-3.043,6.174-1.989,2.437.819,3.466,3.628,3.9,4.915,1.354,3.569.433,6.436,1.246,6.67.975.351,2.87-3.511,3.087-4.037a27.59,27.59,0,0,0,2.491-9.83c.325-4.388.542-7.314-1.029-10.064a6.71,6.71,0,0,1-1.246-2.691c-.271-2.223,1.354-3.452,2.275-5.559.487-1.112.6-2.165-.812-10.239-1.517-8.66-2.058-9.83-2.87-10.064-1.083-.234-2.058,1.346-3.3,2.691-2.166,2.4-5.9,5.032-12.835,6.027a34.657,34.657,0,0,0-.6-6.261c-.487-2.34-.758-2.34-2.491-7.84-.975-3.1-1.191-4.388-1.842-4.447-1.083-.117-1.462,2.867-4.008,3.92-1.137.468-1.571.059-3.087.293a9.749,9.749,0,0,0-5.307,2.75,85.336,85.336,0,0,1-8.665,5.383c-6.012,3.277-8.016-.293-14.244,2.691a22.306,22.306,0,0,0-8.665,8.016,26.119,26.119,0,0,0-4.549,14.335C977.085,448.84,978.169,454.34,980.064,454.633Z"
            transform="translate(-976.874 -406.626)" />
          <g id="Group_1476" data-name="Group 1476" transform="translate(2.983 49.514)">
            <g id="Group_1475" data-name="Group 1475">
              <path id="Path_2524" data-name="Path 2524"
                d="M982.379,491.609a60.079,60.079,0,0,0,4.5,29.138c3.3,7.665,8.557,15.271,16.139,18.314,7.745,3.1,16.627.819,23.072-4.447,6.553-5.383,10.669-13.4,13.106-21.766a.819.819,0,1,0-1.571-.468c-2.329,7.957-6.228,15.622-12.457,20.771-6.445,5.266-15.489,7.489-23.017,3.628-6.77-3.452-11.319-10.649-14.244-17.846a58,58,0,0,1-3.9-27.324c.108-1.112-1.516-1.112-1.625,0Z"
                transform="translate(-982.118 -490.775)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1478" data-name="Group 1478" transform="translate(56.245 50.186)">
            <g id="Group_1477" data-name="Group 1477">
              <path id="Path_2525" data-name="Path 2525"
                d="M1082.053,496.474c.758-3.569,4.6-3.452,6.553-1.17,1.679,1.872,2.058,4.622,2.112,7.138.108,4.213-1.3,11.995-6.878,10.064-.975-.351-1.408,1.346-.433,1.7,7.907,2.75,9.64-9.128,8.666-14.862-.6-3.335-2.6-6.9-6.011-7.372a5.066,5.066,0,0,0-5.578,4.154c-.217.936,1.354,1.4,1.571.351Z"
                transform="translate(-1080.462 -491.924)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1480" data-name="Group 1480" transform="translate(18.505 50.612)">
            <g id="Group_1479" data-name="Group 1479">
              <path id="Path_2526" data-name="Path 2526"
                d="M1015.61,493.09c-1.246,2.809-2.491,5.559-3.683,8.367a10.351,10.351,0,0,0-1.137,4.622c.271,3.452,3.087,5.734,6.012,6.495,1.029.293,1.462-1.4.433-1.7-3.52-.936-5.687-3.979-4.17-7.84,1.191-3.043,2.6-6.027,3.953-9.069.433-.995-.975-1.872-1.408-.878Z"
                transform="translate(-1010.778 -492.652)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1482" data-name="Group 1482" transform="translate(13.263 53.797)">
            <g id="Group_1481" data-name="Group 1481">
              <path id="Path_2527" data-name="Path 2527"
                d="M1003.266,503.013a2.873,2.873,0,0,1-.6-1.463,1.8,1.8,0,0,1,0-.761c0-.176.271-.7.271-.819-.108,0-.162.059-.271.059a1.748,1.748,0,0,1,.054.527v.878a7.358,7.358,0,0,1-.487,2.165,11.907,11.907,0,0,0,1.354-.41,2.142,2.142,0,0,1-.7-1.872c.108-1.112-1.516-1.112-1.625,0a3.678,3.678,0,0,0,1.191,3.1.8.8,0,0,0,1.354-.41,8.856,8.856,0,0,0,.487-4.271c-.162-.995-.921-1.989-1.9-1.521-.921.41-1.3,1.814-1.3,2.867a4.779,4.779,0,0,0,1.137,3.16c.6.878,1.733-.41,1.029-1.229Z"
                transform="translate(-1001.1 -498.096)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1484" data-name="Group 1484" transform="translate(34.059 53.435)">
            <g id="Group_1483" data-name="Group 1483">
              <path id="Path_2528" data-name="Path 2528"
                d="M1041.557,501.879a3.969,3.969,0,0,1-.271-1.346,3.157,3.157,0,0,1,.108-.7c0-.059.054-.176.054-.234.108-.176.054-.176-.217,0,0,.059.108.351.108.468a3.083,3.083,0,0,1-.054.7,4.009,4.009,0,0,1-.6,1.4l1.3.176a2.716,2.716,0,0,1,.108-3.16l-1.3.176a1.489,1.489,0,0,1-.487,2.106c.379.176.812.351,1.191.527a5.861,5.861,0,0,1-.325-1.4c0-.293.054-.585.054-.878,0-.117.054-.176.054-.293-.162-.234-.216-.293-.108-.117a2.816,2.816,0,0,1,.217,1.346c0,.234-.108.585-.108.761,0,.117-.054.175-.054.293.379-.059.433-.176.217-.41a.83.83,0,1,0-1.408.878c.6.936,1.9,1.755,2.6.41a5.366,5.366,0,0,0,0-4.1c-.6-1.112-2-1.463-2.708-.234a5.513,5.513,0,0,0-.054,4.154c.217.527.7.819,1.192.527a3.309,3.309,0,0,0,.812-4.856c-.379-.468-.975-.234-1.3.176a4.6,4.6,0,0,0-.108,4.915.814.814,0,0,0,1.3.176c.975-1.346,2-4.505.054-5.5-.866-.41-1.625.176-1.9,1.053a4.874,4.874,0,0,0,.054,3.394c.433,1.053,2,.644,1.571-.41Z"
                transform="translate(-1039.498 -497.477)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1486" data-name="Group 1486" transform="translate(10.38 46.955)">
            <g id="Group_1485" data-name="Group 1485">
              <path id="Path_2529" data-name="Path 2529"
                d="M1004.183,487.454a5.7,5.7,0,0,0-8.286,1.931c-.542.936.867,1.814,1.408.878a4.167,4.167,0,0,1,6.066-1.346c.866.644,1.679-.878.812-1.463Z"
                transform="translate(-995.775 -486.402)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1488" data-name="Group 1488" transform="translate(32.348 45.415)">
            <g id="Group_1487" data-name="Group 1487">
              <path id="Path_2530" data-name="Path 2530"
                d="M1037.726,486.83a4.464,4.464,0,0,1,7.474,1.872.82.82,0,1,0,1.571-.468,6.046,6.046,0,0,0-10.182-2.633c-.758.7.379,1.931,1.137,1.229Z"
                transform="translate(-1036.338 -483.77)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1490" data-name="Group 1490" transform="translate(35.695 97.632)">
            <g id="Group_1489" data-name="Group 1489">
              <path id="Path_2531" data-name="Path 2531"
                d="M1042.563,574.117a19.389,19.389,0,0,0,4.983,9.011c.758.761,1.9-.468,1.137-1.229a17.4,17.4,0,0,1-4.549-8.25.821.821,0,1,0-1.571.468Z"
                transform="translate(-1042.519 -573.014)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1492" data-name="Group 1492" transform="translate(50.682 87.099)">
            <g id="Group_1491" data-name="Group 1491">
              <path id="Path_2532" data-name="Path 2532"
                d="M1070.2,555.824a25.268,25.268,0,0,0,3.845,10.766.83.83,0,1,0,1.408-.878,22.785,22.785,0,0,1-3.629-9.888c-.108-1.053-1.733-1.112-1.625,0Z"
                transform="translate(-1070.19 -555.012)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1494" data-name="Group 1494" transform="translate(40.281 98.259)">
            <g id="Group_1493" data-name="Group 1493">
              <path id="Path_2533" data-name="Path 2533"
                d="M1051.535,583.865c5.849,2.165,12.565-2.926,14.731-8.718.379-1.053-1.191-1.521-1.57-.468-1.842,4.915-7.853,9.3-12.727,7.489-.975-.351-1.408,1.346-.433,1.7Z"
                transform="translate(-1050.985 -574.085)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1496" data-name="Group 1496" transform="translate(35.692 92.439)">
            <g id="Group_1495" data-name="Group 1495">
              <path id="Path_2534" data-name="Path 2534"
                d="M1043.535,571.371a17.869,17.869,0,0,0,10.128-5.734c.7-.819-.433-2.048-1.137-1.229a16.778,16.778,0,0,1-9.424,5.324c-1.029.176-.6,1.814.433,1.638Z"
                transform="translate(-1042.514 -564.138)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1498" data-name="Group 1498" transform="translate(36.601 89.554)">
            <g id="Group_1497" data-name="Group 1497">
              <path id="Path_2535" data-name="Path 2535"
                d="M1045.225,570.5c4.549-1.4,9.694-5.266,11.752-9.947.433-.995-.975-1.931-1.408-.878-1.9,4.271-6.607,7.9-10.832,9.186a.858.858,0,0,0,.487,1.638Z"
                transform="translate(-1044.191 -559.208)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1500" data-name="Group 1500" transform="translate(0 4.803)">
            <g id="Group_1499" data-name="Group 1499">
              <path id="Path_2536" data-name="Path 2536"
                d="M980.125,453.235c-3.087-3.92-1.841-10.941-.325-15.388,1.841-5.441,5.47-9.537,10.723-11.117,4.6-1.4,9.532-1.229,14.027-3.1,4.982-2.106,8.611-7.9,14.352-7.489,1.029.059,1.029-1.7,0-1.755-6.553-.468-10.561,6.319-16.464,8.133-5.687,1.814-12.023,1.17-17.168,4.739-4.062,2.809-6.607,7.548-7.745,12.521-1.137,4.739-1.733,10.766,1.408,14.686.7.878,1.9-.351,1.192-1.229Z"
                transform="translate(-976.61 -414.361)" />
            </g>
          </g>
          <g id="Group_1502" data-name="Group 1502" transform="translate(18.993)">
            <g id="Group_1501" data-name="Group 1501">
              <path id="Path_2537" data-name="Path 2537"
                d="M1013.532,443.217a18.18,18.18,0,0,1,2.762-11.995c2.6-3.92,6.553-5.676,10.453-7.665a24.415,24.415,0,0,0,8.557-6.67c2.275-2.809,3.412-6.963,6.283-9.128a8.128,8.128,0,0,0-1.137-.293c2.708,6.846,8.124,15.213,3.249,22.468a.83.83,0,1,0,1.408.878c5.253-7.84-.325-16.793-3.25-24.223a.764.764,0,0,0-1.137-.293c-3.141,2.282-4.224,6.378-6.661,9.362-3.2,4.037-7.366,5.851-11.644,8.074a19.265,19.265,0,0,0-10.615,19.484c.217,1.112,1.9,1.112,1.733,0Z"
                transform="translate(-1011.68 -406.152)" />
            </g>
          </g>
          <g id="Group_1504" data-name="Group 1504" transform="translate(34.592 9.648)">
            <g id="Group_1503" data-name="Group 1503">
              <path id="Path_2538" data-name="Path 2538"
                d="M1042.062,449.239a30.256,30.256,0,0,1,16.085-15.037c3.25-1.229,6.661-1.931,9.749-3.628a26.35,26.35,0,0,0,7.745-6.436l-1.3-.176c2,4.622,3.9,9.83,4.279,14.92.379,5.5-2.437,9.713-5.524,13.75-.65.878.487,2.106,1.137,1.229,3.087-4.1,6.12-8.426,6.012-13.926-.108-5.793-2.329-11.7-4.549-16.851a.782.782,0,0,0-1.3-.176,25.644,25.644,0,0,1-10.073,7.372c-3.3,1.287-6.77,1.989-9.965,3.686a32.517,32.517,0,0,0-13.811,14.394c-.379.995,1.029,1.872,1.517.878Z"
                transform="translate(-1040.482 -422.641)" />
            </g>
          </g>
          <g id="Group_1506" data-name="Group 1506" transform="translate(68.361 35.282)">
            <g id="Group_1505" data-name="Group 1505">
              <path id="Path_2539" data-name="Path 2539"
                d="M1104.766,467.767a25.794,25.794,0,0,1-1.787,25.335.83.83,0,1,0,1.408.878,27.489,27.489,0,0,0,1.787-27.09c-.487-.995-1.9-.117-1.408.878Z"
                transform="translate(-1102.834 -466.452)" />
            </g>
          </g>
          <path id="Path_2540" data-name="Path 2540"
            d="M1050.451,484.9a108.862,108.862,0,0,1,0,11.995c-.542,8.894-.975,16.559-4.6,23.755-4.549,8.952-12.348,13.633-17.547,15.973a27.584,27.584,0,0,0,11.1-1.58,28.376,28.376,0,0,0,8.5-4.8c1.029,4.388,2.112,8.777,3.141,13.165,1.625-2.106,3.2-4.271,4.82-6.378-1.408-3.043-2.816-6.144-4.224-9.186,1.137-1.989,2.221-4.1,3.3-6.378a110.675,110.675,0,0,0,4.82-11.41,5.339,5.339,0,0,0,4.6,0c2.058-1.17,2.545-3.8,2.979-6.2.433-2.516,1.625-9.245-2.058-12.17-1.516-1.229-4.17-2.048-6.282-.819a5.764,5.764,0,0,0-2.058,2.4c-.271-2.4-.488-4.8-.758-7.2C1054.3,485.719,1052.346,485.31,1050.451,484.9Z"
            transform="translate(-1000.305 -438.824)" fill="#666" opacity="0.14" />
          <path id="Path_2541" data-name="Path 2541"
            d="M1046.3,447.778a68.528,68.528,0,0,0,9.8-15.388,72.207,72.207,0,0,0,4.6-12.989,17.786,17.786,0,0,1,2.058,10.181c-.921,7.489-7.2,12.17-10.723,14.8A34.812,34.812,0,0,1,1046.3,447.778Z"
            transform="translate(-1008.557 -411.648)" fill="#212121" opacity="0.2" />
          <path id="Path_2542" data-name="Path 2542"
            d="M1066.8,465.243a54.6,54.6,0,0,0,10.561-5.383,57.025,57.025,0,0,0,14.623-14.16c.379,2.457.758,7.314-1.679,11.761C1086.405,464.716,1076.819,468.227,1066.8,465.243Z"
            transform="translate(-1017.954 -422.56)" fill="#212121" opacity="0.16" />
          <path id="Path_2543" data-name="Path 2543"
            d="M1001.276,455.545a34.77,34.77,0,0,1,1.083-10.005c2.437-9.245,8.5-16.149,9.424-15.564C1012.541,430.444,1010.7,435.944,1001.276,455.545Z"
            transform="translate(-987.904 -416.022)" fill="#212121" opacity="0.22" />
          <g id="Group_1508" data-name="Group 1508" transform="translate(15.443 70.799)">
            <g id="Group_1507" data-name="Group 1507">
              <path id="Path_2544" data-name="Path 2544"
                d="M1005.707,533.558a44.109,44.109,0,0,0,23.18-5.207c.7-.351.108-1.521-.6-1.112a42.886,42.886,0,0,1-22.53,5.032c-.867-.058-.812,1.229-.054,1.287Z"
                transform="translate(-1005.125 -527.153)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1510" data-name="Group 1510" transform="translate(16.759 76.445)">
            <g id="Group_1509" data-name="Group 1509">
              <path id="Path_2545" data-name="Path 2545"
                d="M1007.633,537.8a24.481,24.481,0,0,1,2.275,6.729c.162.819,1.3.468,1.192-.351a25.21,25.21,0,0,0-2.383-7.08c-.379-.7-1.462,0-1.083.7Z"
                transform="translate(-1007.554 -536.803)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1512" data-name="Group 1512" transform="translate(19.049 71.179)">
            <g id="Group_1511" data-name="Group 1511">
              <path id="Path_2546" data-name="Path 2546"
                d="M1011.955,540.849c3.25,3.394,8.5,1.7,11.969-.468a17.855,17.855,0,0,0,8.232-11.761c.162-.819-1.029-1.17-1.192-.351a16.825,16.825,0,0,1-6.336,10.122c-3.087,2.282-8.665,4.856-11.807,1.521-.542-.585-1.408.351-.866.936Z"
                transform="translate(-1011.783 -527.803)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1514" data-name="Group 1514" transform="translate(18.031 71.546)">
            <g id="Group_1513" data-name="Group 1513">
              <path id="Path_2547" data-name="Path 2547"
                d="M1010.335,536.721c3.683,1.053,7.907-.117,11.427-1.287a18.662,18.662,0,0,0,8.936-5.851c.542-.644-.325-1.58-.866-.936a17.552,17.552,0,0,1-8.395,5.5c-3.3,1.053-7.366,2.282-10.777,1.287-.758-.176-1.083,1.112-.325,1.287Z"
                transform="translate(-1009.903 -528.43)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1516" data-name="Group 1516" transform="translate(18.944 76.239)">
            <g id="Group_1515" data-name="Group 1515">
              <path id="Path_2548" data-name="Path 2548"
                d="M1011.649,540.693l1.3,3.335c.271.7,1.354.176,1.137-.527a20.8,20.8,0,0,1-.866-3.16l-.758.819a22.109,22.109,0,0,0,3.954.351c.65,0,.866-1.17.162-1.287a6.385,6.385,0,0,0-3.466.234.7.7,0,0,0-.379.995c.325.527.7,1.112,1.029,1.638a.607.607,0,1,0,1.029-.644c-.162-.527-.325-1.053-.487-1.521l-1.137.527a2.9,2.9,0,0,1,.542,2.457l1.137-.351c-.758-1.346-1.408.234-1.571.644-.162.527.487,1.112.921.761,4.441-3.628,9.64-5.441,14.894-7.2.758-.234.433-1.521-.325-1.287-5.362,1.755-10.669,3.628-15.164,7.314a10.565,10.565,0,0,1,.921.761c.217-.527.866-.644.975-1.463.108-.7-.162-1.755-.867-1.989-1.95-.7-1.246,2.282-.758,3.1.325.585,1.083.292,1.137-.351a4.7,4.7,0,0,0-.7-3.1.633.633,0,0,0-1.137.527,7.483,7.483,0,0,0,.6,1.872,9.9,9.9,0,0,1,1.029-.644c-.325-.527-.7-1.112-1.029-1.638a7.483,7.483,0,0,1-.379.995,4.243,4.243,0,0,1,2.816-.234c.054-.41.108-.878.162-1.287a19.338,19.338,0,0,1-3.629-.293.638.638,0,0,0-.758.819,20.82,20.82,0,0,0,.867,3.16l1.137-.527-1.3-3.335c-.271-.936-1.354-.234-1.029.527Z"
                transform="translate(-1011.589 -536.452)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1518" data-name="Group 1518" transform="translate(21.378 73.492)">
            <g id="Group_1517" data-name="Group 1517">
              <path id="Path_2549" data-name="Path 2549"
                d="M1017.175,540.851a32.039,32.039,0,0,1,5.958-2.984l-.487-1.229c-1.9.936-3.791,1.872-5.633,2.75-.6.293-.162,1.463.487,1.229,5.091-2.165,10.128-4.388,14.731-7.665a10.521,10.521,0,0,1-.921-.761c-.7,2.048-3.845,2.282-5.47,2.926-1.354.527-2.654,1.229-4.008,1.755-1.9.7-4.116.585-5.578,2.34-.542.644.325,1.58.867.936,1.192-1.463,3.412-1.4,5.037-1.989s3.2-1.463,4.874-2.048c2.275-.761,4.549-.819,5.47-3.511.163-.526-.487-1.053-.921-.761a76.463,76.463,0,0,1-14.46,7.489l.487,1.229c1.9-.936,3.791-1.872,5.632-2.75.65-.293.163-1.463-.487-1.229a36.831,36.831,0,0,0-6.228,3.1c-.65.468,0,1.638.65,1.17Z"
                transform="translate(-1016.083 -531.757)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1520" data-name="Group 1520" transform="translate(35.856 75.097)">
            <g id="Group_1519" data-name="Group 1519">
              <path id="Path_2550" data-name="Path 2550"
                d="M1044.053,535.553c0-.058-.054-.117-.054-.175v.117l-.217.234h.054c-.163,0-.325.058-.488.058-.271-.058-.271-.585-.325-.234a.565.565,0,0,0,.054.293.875.875,0,0,0,.379.527.536.536,0,0,0,.812-.234.674.674,0,0,0-.217-.878.054.054,0,0,1-.054-.059l.217.234c.054.176.054.176.054.059v-.117a1.175,1.175,0,0,0-.108-.468.816.816,0,0,0-.65-.41c-.7,0-.812.878-.6,1.4a.591.591,0,0,0,.758.468c.271-.117.542-.527.379-.819Z"
                transform="translate(-1042.816 -534.5)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1522" data-name="Group 1522" transform="translate(36.77 69.537)">
            <g id="Group_1521" data-name="Group 1521">
              <path id="Path_2551" data-name="Path 2551"
                d="M1044.935,526.286a5.388,5.388,0,0,1,2.491,2.282c.379.7,1.462.058,1.029-.644a6.283,6.283,0,0,0-3.2-2.867c-.758-.351-1.083.936-.325,1.229Z"
                transform="translate(-1044.503 -524.997)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1524" data-name="Group 1524" transform="translate(21.521 77.07)">
            <g id="Group_1523" data-name="Group 1523">
              <path id="Path_2552" data-name="Path 2552"
                d="M1017.242,545.963a37.475,37.475,0,0,1,14.081-6.787c.758-.176.433-1.463-.325-1.287a37.835,37.835,0,0,0-14.406,6.9c-.6.527,0,1.7.65,1.17Z"
                transform="translate(-1016.347 -537.872)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1526" data-name="Group 1526" transform="translate(20.724 77.597)">
            <g id="Group_1525" data-name="Group 1525">
              <path id="Path_2553" data-name="Path 2553"
                d="M1015.484,545.283a26.848,26.848,0,0,0,14.623-5.266c.65-.468.054-1.638-.6-1.112a25.378,25.378,0,0,1-14.027,5.09c-.812.058-.812,1.346,0,1.287Z"
                transform="translate(-1014.875 -538.772)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1528" data-name="Group 1528" transform="translate(25.516 77.735)">
            <g id="Group_1527" data-name="Group 1527">
              <path id="Path_2554" data-name="Path 2554"
                d="M1024.469,543.918a25.033,25.033,0,0,0,8.34-3.686c.65-.468.054-1.58-.6-1.112a24.343,24.343,0,0,1-8.016,3.569c-.812.117-.487,1.4.271,1.229Z"
                transform="translate(-1023.724 -539.007)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1530" data-name="Group 1530" transform="translate(21.956 79.586)">
            <g id="Group_1529" data-name="Group 1529">
              <path id="Path_2555" data-name="Path 2555"
                d="M1017.719,547.513a20.642,20.642,0,0,0,11.915-4.1c.65-.468.054-1.638-.6-1.112a19.619,19.619,0,0,1-11.319,3.92c-.758,0-.758,1.346,0,1.287Z"
                transform="translate(-1017.15 -542.172)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1532" data-name="Group 1532" transform="translate(22.522 83.447)">
            <g id="Group_1531" data-name="Group 1531">
              <path id="Path_2556" data-name="Path 2556"
                d="M1018.2,550.134c-.054.995.812,1.112,1.516.995a43.4,43.4,0,0,0,4.766-1.053.669.669,0,0,0-.325-1.287c-.812.234-1.625.41-2.491.585-.379.059-2.22.175-2.275.7.108-.761-1.138-.761-1.192.058Z"
                transform="translate(-1018.196 -548.771)" fill="#161616" />
            </g>
          </g>
        </g>
        <g id="hand_left" transform="translate(128.33 63.066)">
          <path id="Path_2557" data-name="Path 2557"
            d="M1037.268,543.437c.542-1.638,5.416-1.287,9.64-.7a11.285,11.285,0,0,1,.65-3.569c.379-1.112.975-2.223,3.141-4.8,2.329-2.75,3.52-4.1,5.037-5.09a14.845,14.845,0,0,1,5.687-2.223c1.083.41,2.22.819,3.3,1.17.325-1.112.975-3.218,2.221-7.314.812-2.75,1.3-4.154,2.383-4.622a3.027,3.027,0,0,1,3.3.995c1.083,1.463.108,3.569-.487,7.138-.325,2.048-.054,1.7-.162,9.362-.109,7.841-.379,7.782-.325,11.059a56.374,56.374,0,0,0,1.733,12.58,11.656,11.656,0,0,1,5.362-1.17c5.308.058,9.045,3.8,11.807,6.67,5.416,5.559,7.744,11.7,9.315,16.032a57.226,57.226,0,0,1,3.466,18.372c.054,4.739.163,9.186-2.058,14.335a22.7,22.7,0,0,1-7.1,9.362c-1.841,1.4-5.307,3.979-9.8,3.569a12.926,12.926,0,0,1-7.42-3.569c-8.34-7.314-12.186-20.6-13.377-25.218a154.411,154.411,0,0,1-5.2-35.926,12.152,12.152,0,0,1-4.116-.995c-5.578-2.516-7.745-8.542-8.178-10.064-1.137.058-5.2,0-7.745-3.043C1037.809,545.076,1037.051,544.2,1037.268,543.437Z"
            transform="translate(-1036.319 -514.847)" fill="#fff" />
          <g id="Group_1534" data-name="Group 1534" transform="translate(0 26.901)">
            <g id="Group_1533" data-name="Group 1533">
              <path id="Path_2558" data-name="Path 2558"
                d="M1044.025,565.7c-2.87-.234-6.174-1.4-6.932-4.8-.162.351-.379.7-.542,1.053,4.17-.936,8.34.41,12.511-.234,1.029-.176.6-1.872-.433-1.7-4.17.644-8.34-.7-12.51.234a.875.875,0,0,0-.542,1.053c.921,4.213,4.874,5.793,8.5,6.085,1.029.117,1.029-1.638-.054-1.7Z"
                transform="translate(-1035.549 -559.913)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1536" data-name="Group 1536" transform="translate(9.916 21.05)">
            <g id="Group_1535" data-name="Group 1535">
              <path id="Path_2559" data-name="Path 2559"
                d="M1055.506,555.288c-.65-4.154,5.253-3.92,7.474-3.277,1.029.293,1.463-1.4.433-1.7-3.629-1.053-10.344-.292-9.478,5.383.162,1.17,1.733.7,1.571-.41Z"
                transform="translate(-1053.859 -549.913)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1538" data-name="Group 1538" transform="translate(12.301 15.294)">
            <g id="Group_1537" data-name="Group 1537">
              <path id="Path_2560" data-name="Path 2560"
                d="M1059.853,547.169a6.98,6.98,0,0,1,3.358-4.856c2.166-1.053,4.333,0,6.336.936.921.468,1.787-1.053.813-1.521-2.491-1.229-5.145-2.4-7.8-1.053a8.629,8.629,0,0,0-4.279,6.026c-.217,1.112,1.354,1.58,1.571.468Z"
                transform="translate(-1058.262 -540.075)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1540" data-name="Group 1540" transform="translate(17.943 11.063)">
            <g id="Group_1539" data-name="Group 1539">
              <path id="Path_2561" data-name="Path 2561"
                d="M1070.315,537.148a11.652,11.652,0,0,1,2-1.931,5.29,5.29,0,0,1,2.491-.643,10.266,10.266,0,0,1,5.687,2.282c.867.585,1.679-.878.812-1.521-2.708-1.872-6.012-3.335-9.207-1.931-1.354.585-4.6,3.218-2.979,5.032.758.761,1.9-.468,1.191-1.287Z"
                transform="translate(-1068.679 -532.844)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1542" data-name="Group 1542" transform="translate(28.2)">
            <g id="Group_1541" data-name="Group 1541">
              <path id="Path_2562" data-name="Path 2562"
                d="M1095.083,544.107a76.294,76.294,0,0,0,.542-14.92,25.934,25.934,0,0,1,.758-7.9,9.528,9.528,0,0,0,.217-4.447c-.6-1.989-2.654-3.628-4.6-2.574-1.246.7-1.571,2.282-2,3.628-.812,2.516-1.571,5.09-2.329,7.606a.819.819,0,1,0,1.571.468c.433-1.463.867-2.926,1.354-4.33.271-.878.7-3.8,1.354-4.33,2.708-.351,3.791.468,3.141,2.575-.162.7-.325,1.346-.542,2.048a23.37,23.37,0,0,0-.65,3.277c-.217,2.809.271,5.734.271,8.6a75.136,75.136,0,0,1-.65,9.888c-.108,1.053,1.462,1.521,1.571.409Z"
                transform="translate(-1087.619 -513.937)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1544" data-name="Group 1544" transform="translate(6.818 32.856)">
            <g id="Group_1543" data-name="Group 1543">
              <path id="Path_2563" data-name="Path 2563"
                d="M1048.93,571.793c2.816.234,3.52,3.394,4.82,5.5a11.142,11.142,0,0,0,2.437,2.926,11.473,11.473,0,0,0,6.553,2.75c1.029.117,1.029-1.638,0-1.755-4.116-.41-6.716-2.984-8.611-6.729-1.192-2.34-2.6-4.213-5.2-4.388-1.083-.117-1.029,1.638,0,1.7Z"
                transform="translate(-1048.137 -570.09)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1546" data-name="Group 1546" transform="translate(21.105 43.911)">
            <g id="Group_1545" data-name="Group 1545">
              <path id="Path_2564" data-name="Path 2564"
                d="M1074.563,590.047c1.679,6.261,1.842,12.7,2.329,19.133a88.85,88.85,0,0,0,2.654,16.09,64.815,64.815,0,0,0,14.406,26.856c.758.819,1.9-.468,1.137-1.229a63.081,63.081,0,0,1-14.46-27.91c-2.708-11-1.571-22.527-4.5-33.41a.82.82,0,1,0-1.571.468Z"
                transform="translate(-1074.519 -588.985)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1548" data-name="Group 1548" transform="translate(34.088 28.641)">
            <g id="Group_1547" data-name="Group 1547">
              <path id="Path_2565" data-name="Path 2565"
                d="M1098.5,563.714a73.742,73.742,0,0,0,7.907,27.851c4.333,8.308,10.994,15.505,12.511,25.218.162,1.112,1.733.643,1.571-.468-1.354-8.835-6.932-15.564-11.211-22.936a72.21,72.21,0,0,1-9.153-29.606c-.108-1.17-1.733-1.17-1.625-.059Z"
                transform="translate(-1098.49 -562.887)" fill="#161616" />
            </g>
          </g>
          <g id="Group_1550" data-name="Group 1550" transform="translate(36.843 40.607)">
            <g id="Group_1549" data-name="Group 1549">
              <path id="Path_2566" data-name="Path 2566"
                d="M1104.565,585.774c8.07-2.809,16.031,4.213,20.255,11,4.441,7.138,6.445,15.915,7.366,24.4.866,8.308.271,17.378-4.874,24.048-4.766,6.2-13.594,8.659-19.605,3.042-.812-.761-1.95.527-1.137,1.229,5.687,5.324,14.081,4.564,19.66-.585,6.824-6.261,8.5-16.442,7.8-25.628-.758-10.005-3.087-20.713-8.72-28.9-4.712-6.787-12.944-13.165-21.122-10.356-1.029.41-.65,2.106.379,1.755Z"
                transform="translate(-1103.578 -583.339)" fill="#161616" />
            </g>
          </g>
          <path id="Path_2567" data-name="Path 2567"
            d="M1096.74,519.781c-2.87,1.17-2.654,9.128-2.437,14.862.758,17.027,1.137,25.569,4.224,38.676,4.441,18.665,8.5,23.521,10.723,25.8,3.2,3.277,9.315,8.016,11.915,6.144,4.116-2.984-2.437-20.771-4.062-25.1-5.47-14.745-6.661-19.659-10.723-21.181a10.216,10.216,0,0,0-6.5,0,29.994,29.994,0,0,1-2.654-12.7c.054-2.516.433-2.516.6-6.319.325-6.9-.812-7.314-.217-11.819.6-4.388,2.166-7.021,1.029-8.074C1098.256,519.489,1097.336,519.547,1096.74,519.781Z"
            transform="translate(-1062.43 -516.292)" fill="#666" opacity="0.15" />
          <path id="Path_2568" data-name="Path 2568"
            d="M1107.5,592a59.825,59.825,0,0,1,8.286,10.941,62.519,62.519,0,0,1,6.878,16.383,66.536,66.536,0,0,1,1.625,8.952,58.1,58.1,0,0,0-5.686-12.7c-2.437-4.1-4.008-5.5-6.066-9.186A47.294,47.294,0,0,1,1107.5,592Z"
            transform="translate(-1068.532 -546.325)" fill="#666" opacity="0.15" />
        </g>
      </g>
      <g id="Group_1632" data-name="Group 1632" transform="translate(581.435 428.527)">
        <path id="Path_2569" data-name="Path 2569"
          d="M149.438,528.047q-.143,28.768-.238,57.584,131.03-.5,262.061-.949Q410.9,556.341,410.5,528,279.969,528,149.438,528.047Z"
          transform="translate(-147.487 -525.748)" />
        <g id="Group_1554" data-name="Group 1554" transform="translate(0 0.7)">
          <g id="Group_1393" data-name="Group 1393">
            <path id="Path_2466" data-name="Path 2466"
              d="M145.6,526.264v55.78a1.428,1.428,0,0,0,2.855,0v-55.78a1.428,1.428,0,0,0-2.855,0Z"
              transform="translate(-145.6 -524.875)" />
          </g>
        </g>
        <g id="Group_1555" data-name="Group 1555" transform="translate(1.368)">
          <g id="Group_1395" data-name="Group 1395">
            <path id="Path_2467" data-name="Path 2467"
              d="M149.867,526.248H411.452a1.425,1.425,0,0,0,0-2.848H149.867a1.425,1.425,0,0,0,0,2.848Z"
              transform="translate(-148.475 -523.4)" />
          </g>
        </g>
        <g id="Group_1556" data-name="Group 1556" transform="translate(262.774 0.024)">
          <g id="Group_1397" data-name="Group 1397">
            <path id="Path_2468" data-name="Path 2468"
              d="M697.9,524.8q.357,29.053.714,58.154a1.428,1.428,0,0,0,2.855,0q-.357-29.053-.714-58.154a1.429,1.429,0,0,0-2.855,0Z"
              transform="translate(-697.9 -523.45)" />
          </g>
        </g>
        <g id="Group_1557" data-name="Group 1557" transform="translate(0.036 57.774)">
          <g id="Group_1399" data-name="Group 1399">
            <path id="Path_2469" data-name="Path 2469"
              d="M147.067,647.948H410.65a1.425,1.425,0,0,0,0-2.848H147.067a1.425,1.425,0,0,0,0,2.848Z"
              transform="translate(-145.675 -645.1)" />
          </g>
        </g>
        <g id="Group_1565" data-name="Group 1565" transform="translate(80.623 14.368)">
          <g id="Group_1415" data-name="Group 1415">
            <path id="Path_2477" data-name="Path 2477"
              d="M467.033,549.974a25.531,25.531,0,0,0-11.793,16.506c-.983,4.941-1.041,13.877,5.087,16.243s10.984-5.2,8.729-9.935c-2.139-4.468-12.2-5.046-13.759.158-.289,1,1.387,1.419,1.676.421.809-2.628,5.145-2.839,7.515-2.208,3.584.946,4.1,4.784,2.544,7.464-1.908,3.259-6.07,3.89-8.44.683a12.33,12.33,0,0,1-2.081-6.255c-.809-8.463,3.642-16.926,11.331-21.762a.773.773,0,1,0-.809-1.314Z"
              transform="translate(-454.747 -549.834)" fill="#fff" />
          </g>
        </g>
        <g id="Group_1628" data-name="Group 1628" transform="translate(136.243 13.56)">
          <g id="Group_1630" data-name="Group 1630">
            <g id="Group_1566" data-name="Group 1566">
              <g id="Group_1417" data-name="Group 1417">
                <path id="Path_2478" data-name="Path 2478"
                  d="M532.186,556.242c-.143-.332-1-1.994-.809-2.611-.19-.617.143-.76.9-.475h.952c1.57-.047,3.093-.047,4.663-.095,3.093-.047,6.185-.142,9.325-.19-.19-.38-.428-.712-.618-1.092a104.241,104.241,0,0,0-10.087,33.706c.476-.047.952-.142,1.38-.19l-.143-1c-.143-.9-1.522-.522-1.38.38l.143,1c.143.854,1.332.522,1.38-.19a102.968,102.968,0,0,1,9.9-32.993.736.736,0,0,0-.619-1.092c-5.091.095-10.229.095-15.368.332-3.188.142-1.808,3.133-.9,5.222.381.854,1.618.095,1.285-.712Z"
                  transform="translate(-529.814 -551.4)" fill="#fff" />
              </g>
            </g>
            <g id="Group_1567" data-name="Group 1567" transform="translate(3.616 17.384)">
              <g id="Group_1419" data-name="Group 1419">
                <path id="Path_2479" data-name="Path 2479"
                  d="M537.93,589.956a23.756,23.756,0,0,0,12.751-.522c.856-.285.476-1.661-.381-1.377a22.374,22.374,0,0,1-11.99.522.714.714,0,0,0-.381,1.377Z"
                  transform="translate(-537.414 -588.019)" fill="#fff" />
              </g>
            </g>
          </g>
        </g>
        <g id="Group_1570" data-name="Group 1570" transform="translate(186.203 14.368)">
          <g id="Group_1425" data-name="Group 1425">
            <path id="Path_2482" data-name="Path 2482"
              d="M638.227,551.963c2.617-2.5-.523-5.563-3.426-5.563-3.521.046-8.707,2.318-9.611,5.934-.809,3.291,2.331,6.351,5.662,6.444,4.092.093,6.708-3.709,7.137-7.278-.476.046-.952.139-1.38.185a61.547,61.547,0,0,1,.856,18.914c-.333,2.781-.856,6.676-4.139,7.649-3.806,1.113-6.9-2.132-8.707-4.914-.476-.742-1.713-.046-1.237.7,1.808,2.735,4.234,5.424,7.755,5.748a6.281,6.281,0,0,0,6.613-4.079c1.427-3.384,1.427-7.6,1.427-11.172a64.81,64.81,0,0,0-1.285-13.212c-.143-.834-1.332-.51-1.38.185a6.517,6.517,0,0,1-4.33,5.7c-2.379.7-5.281-.834-5.662-3.338-.381-2.411,1.9-3.987,3.9-4.914a15.083,15.083,0,0,1,3.093-1.02,5.365,5.365,0,0,1,2.426-.046c1.57.51,1.951,1.53,1.189,3.06-.571.649.428,1.669,1.094,1.02Z"
              transform="translate(-623.275 -546.4)" fill="#fff" />
          </g>
        </g>
        <g id="Group_1571" data-name="Group 1571" transform="translate(13.967 13.56)">
          <g id="Group_1425-2" data-name="Group 1425">
            <path id="Path_2482-2" data-name="Path 2482"
              d="M638.227,552.1c2.617-2.564-.523-5.7-3.426-5.7-3.521.047-8.707,2.373-9.611,6.076-.809,3.371,2.331,6.5,5.662,6.6,4.092.095,6.708-3.8,7.137-7.453-.476.047-.952.142-1.38.19a64.517,64.517,0,0,1,.856,19.369c-.333,2.848-.856,6.836-4.139,7.833-3.806,1.139-6.9-2.184-8.707-5.032-.476-.76-1.713-.048-1.237.712,1.808,2.8,4.234,5.554,7.755,5.887a6.272,6.272,0,0,0,6.613-4.178c1.427-3.465,1.427-7.785,1.427-11.441a67.915,67.915,0,0,0-1.285-13.53c-.143-.855-1.332-.522-1.38.19-.285,2.516-1.76,5.08-4.33,5.839a4.519,4.519,0,0,1-5.662-3.418c-.381-2.469,1.9-4.083,3.9-5.032a14.829,14.829,0,0,1,3.093-1.044,5.239,5.239,0,0,1,2.426-.048c1.57.522,1.951,1.567,1.189,3.133-.571.665.428,1.709,1.094,1.044Z"
              transform="translate(-623.275 -546.4)" fill="#fff" />
          </g>
        </g>
        <g id="Group_1576" data-name="Group 1576" transform="translate(66.55 11.945)">
          <g id="Group_1401" data-name="Group 1401">
            <path id="Path_2470" data-name="Path 2470"
              d="M193.723,559.718a36.373,36.373,0,0,0,3.283-13.145h-1.427q-.285,18.017-.523,35.991c0,.8,1.427.8,1.427,0q.285-18.016.523-35.991c0-.8-1.38-.8-1.427,0a34.809,34.809,0,0,1-3.235,12.809c-.333.756,1.047,1.05,1.38.336Z"
              transform="translate(-192.292 -545.975)" fill="#fff" />
          </g>
        </g>
        <g id="Group_1577" data-name="Group 1577" transform="translate(160.772 14.368)">
          <g id="Group_1403" data-name="Group 1403">
            <path id="Path_2471" data-name="Path 2471"
              d="M231.324,562.607a7.049,7.049,0,0,1-1-5.8c.651-2.329,3.73-5.251,5.768-2.117,1.735,2.71.043,7.454-.824,10.249A40.5,40.5,0,0,1,224.862,582a.635.635,0,0,0,.3,1.059c5.552,2.033,12.014,4.023,17.305.3.694-.466.043-1.567-.651-1.1-5.031,3.515-11.1,1.482-16.308-.424a9.712,9.712,0,0,0,.3,1.059,42.643,42.643,0,0,0,8.544-11.985c1.952-4.15,3.86-9.232,3.687-13.891-.13-3.261-3.079-6.479-6.462-4.235-3.426,2.287-3.383,7.242-1.388,10.418.434.72,1.561.085,1.128-.593Z"
              transform="translate(-224.679 -552.034)" fill="#fff" />
          </g>
        </g>
        <g id="Group_1629" data-name="Group 1629" transform="translate(215.205 13.56)">
          <g id="Group_1584" data-name="Group 1584">
            <g id="Group_1417-2" data-name="Group 1417">
              <path id="Path_2478-2" data-name="Path 2478"
                d="M532.186,556.242c-.143-.332-1-1.994-.809-2.611-.19-.617.143-.76.9-.475h.952c1.57-.047,3.093-.047,4.663-.095,3.093-.047,6.185-.142,9.325-.19-.19-.38-.428-.712-.618-1.092a104.241,104.241,0,0,0-10.087,33.706c.476-.047.952-.142,1.38-.19l-.143-1c-.143-.9-1.522-.522-1.38.38l.143,1c.143.854,1.332.522,1.38-.19a102.968,102.968,0,0,1,9.9-32.993.736.736,0,0,0-.619-1.092c-5.091.095-10.229.095-15.368.332-3.188.142-1.808,3.133-.9,5.222.381.854,1.618.095,1.285-.712Z"
                transform="translate(-529.814 -551.4)" fill="#fff" />
            </g>
          </g>
          <g id="Group_1585" data-name="Group 1585" transform="translate(3.616 17.384)">
            <g id="Group_1419-2" data-name="Group 1419">
              <path id="Path_2479-2" data-name="Path 2479"
                d="M537.93,589.956a23.756,23.756,0,0,0,12.751-.522c.856-.285.476-1.661-.381-1.377a22.374,22.374,0,0,1-11.99.522.714.714,0,0,0-.381,1.377Z"
                transform="translate(-537.414 -588.019)" fill="#fff" />
            </g>
          </g>
        </g>
        <g id="Group_1631" data-name="Group 1631" transform="translate(238.509 14.368)">
          <g id="Group_1586" data-name="Group 1586" transform="translate(0.795 1.256)">
            <g id="Group_1421" data-name="Group 1421">
              <path id="Path_2480" data-name="Path 2480"
                d="M596.61,555.29c-2.074,4.931-4.106,9.862-6.18,14.792-.974,2.352-1.947,4.659-2.921,7.012-.931,2.216-1.651,5.067-2.963,7.057a.646.646,0,0,0,1.1.679c1.185-1.855,1.9-4.569,2.794-6.65,1.016-2.488,2.074-4.931,3.09-7.419,2.074-4.931,4.106-9.862,6.18-14.792.339-.769-.762-1.448-1.1-.679Z"
                transform="translate(-584.434 -554.952)" fill="#fff" />
            </g>
          </g>
          <g id="Group_1587" data-name="Group 1587" transform="translate(0 0)">
            <g id="Group_1423" data-name="Group 1423">
              <path id="Path_2481" data-name="Path 2481"
                d="M595.992,553.5c-2.582-2.759-6.688-.633-9.185,1.221-2.963,2.171-5.249,6.016-3.81,9.952,1.608,4.478,6.265,5.7,9.27,8.731,2.413,2.4,3.513,6.243.508,8.685-2.5,2.036-5.8,1.583-8.72,1-.8-.181-1.143,1.131-.339,1.312,3.6.724,7.7,1.131,10.54-1.9,2.455-2.624,1.99-6.378-.127-9.047-2.836-3.664-8.762-4.8-10.074-9.771-.889-3.3,1.312-6.288,3.725-8.007,1.9-1.357,5.376-3.393,7.407-1.267.466.679,1.354-.271.8-.9Z"
                transform="translate(-582.555 -552.175)" fill="#fff" />
            </g>
          </g>
        </g>
        <g id="Group_1626" data-name="Group 1626" transform="translate(37.565 13.56)">
          <g id="Group_1425-3" data-name="Group 1425">
            <path id="Path_2482-3" data-name="Path 2482"
              d="M638.227,552.1c2.617-2.564-.523-5.7-3.426-5.7-3.521.047-8.707,2.373-9.611,6.076-.809,3.371,2.331,6.5,5.662,6.6,4.092.095,6.708-3.8,7.137-7.453-.476.047-.952.142-1.38.19a64.517,64.517,0,0,1,.856,19.369c-.333,2.848-.856,6.836-4.139,7.833-3.806,1.139-6.9-2.184-8.707-5.032-.476-.76-1.713-.048-1.237.712,1.808,2.8,4.234,5.554,7.755,5.887a6.272,6.272,0,0,0,6.613-4.178c1.427-3.465,1.427-7.785,1.427-11.441a67.915,67.915,0,0,0-1.285-13.53c-.143-.855-1.332-.522-1.38.19-.285,2.516-1.76,5.08-4.33,5.839a4.519,4.519,0,0,1-5.662-3.418c-.381-2.469,1.9-4.083,3.9-5.032a14.829,14.829,0,0,1,3.093-1.044,5.239,5.239,0,0,1,2.426-.048c1.57.522,1.951,1.567,1.189,3.133-.571.665.428,1.709,1.094,1.044Z"
              transform="translate(-623.275 -546.4)" fill="#fff" />
          </g>
        </g>
        <g id="Group_1627" data-name="Group 1627" transform="translate(104.855 13.56)">
          <g id="Group_1425-4" data-name="Group 1425">
            <path id="Path_2482-4" data-name="Path 2482"
              d="M638.227,552.1c2.617-2.564-.523-5.7-3.426-5.7-3.521.047-8.707,2.373-9.611,6.076-.809,3.371,2.331,6.5,5.662,6.6,4.092.095,6.708-3.8,7.137-7.453-.476.047-.952.142-1.38.19a64.517,64.517,0,0,1,.856,19.369c-.333,2.848-.856,6.836-4.139,7.833-3.806,1.139-6.9-2.184-8.707-5.032-.476-.76-1.713-.048-1.237.712,1.808,2.8,4.234,5.554,7.755,5.887a6.272,6.272,0,0,0,6.613-4.178c1.427-3.465,1.427-7.785,1.427-11.441a67.915,67.915,0,0,0-1.285-13.53c-.143-.855-1.332-.522-1.38.19-.285,2.516-1.76,5.08-4.33,5.839a4.519,4.519,0,0,1-5.662-3.418c-.381-2.469,1.9-4.083,3.9-5.032a14.829,14.829,0,0,1,3.093-1.044,5.239,5.239,0,0,1,2.426-.048c1.57.522,1.951,1.567,1.189,3.133-.571.665.428,1.709,1.094,1.044Z"
              transform="translate(-623.275 -546.4)" fill="#fff" />
          </g>
        </g>
      </g>
      <text id="phone_code" data-name="+91-" transform="translate(510 468.496)" font-size="26"
        font-family="Noteworthy-Light, Noteworthy" font-weight="300">
        <tspan x="0" y="0">+91-</tspan>
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ContactMe"
}
</script>

<style lang="scss" scoped>
:root {
  .contact_me_svg {
    #phone_code {
      fill: rgba(63, 61, 86, 0.89);
    }

    #Path_2569 {
      fill: #6c63ff;
    }

    #Path_2467,
    #Path_2466,
    #Path_2468,
    #Path_2469 {
      fill: #161616;
    }

    #Path_2464,
    #Path_2455,
    #Path_2453,
    #Path_2454,
    #Path_2451,
    #Path_2452 {
      fill: #5222d0;
    }

    #Path_2483,
    #Path_2484,
    #Path_2485 {
      fill: #161616;
    }

    #Path_2523 {
      fill: #6c63ff;
    }

    #Path_2536,
    #Path_2537,
    #Path_2538,
    #Path_2539 {
      fill: #5222d0;
    }

    #Path_2519 {
      fill: #ef7974;
    }

    #Path_2428,
    #Path_2429,
    #Path_2430,
    #Path_2431,
    #Path_2432,
    #Path_2433,
    #Path_2434,
    #Path_2435,
    #Path_2436,
    #Path_2437,
    #Path_2438,
    #Path_2439,
    #Path_2440,
    #Path_2441,
    #Path_2442,
    #Path_2443,
    #Path_2424,
    #Path_2425,
    #Path_2426,
    #Path_2427,
    #Path_2415,
    #Path_2416,
    #Path_2417,
    #Path_2418,
    #Path_2419,
    #Path_2420,
    #Path_2421,
    #Path_2422,
    #Path_2423 {
      fill: #e8e8e8;
    }

    #Path_2444,
    #Path_2445,
    #Path_2446,
    #Path_2447,
    #Path_2448,
    #Path_2449,
    #Path_2450 {
      fill: #161616;
    }
  }
}

:root.dark-theme {
  .contact_me_svg {
    #phone_code {
      fill: #E7EAEC;
    }

    #Path_2569 {
      fill: #181922;
    }

    #Path_2467,
    #Path_2466,
    #Path_2468,
    #Path_2469 {
      fill: #F8BF7B;
    }

    #Path_2464,
    #Path_2455,
    #Path_2453,
    #Path_2454,
    #Path_2451,
    #Path_2452 {
      fill: #F8BF7B;
    }

    #Path_2483,
    #Path_2484,
    #Path_2485 {
      fill: #56575E;
    }

    #Path_2523 {
      fill: #555A68;
    }

    #Path_2536,
    #Path_2537,
    #Path_2538,
    #Path_2539 {
      fill: #747986;
    }

    #Path_2519 {
      fill: #F8BF7B;
    }

    #Path_2428,
    #Path_2429,
    #Path_2430,
    #Path_2431,
    #Path_2432,
    #Path_2433,
    #Path_2434,
    #Path_2435,
    #Path_2436,
    #Path_2437,
    #Path_2438,
    #Path_2439,
    #Path_2440,
    #Path_2441,
    #Path_2442,
    #Path_2443,
    #Path_2424,
    #Path_2425,
    #Path_2426,
    #Path_2427,
    #Path_2415,
    #Path_2416,
    #Path_2417,
    #Path_2418,
    #Path_2419,
    #Path_2420,
    #Path_2421,
    #Path_2422,
    #Path_2423 {
      fill: #006060;
    }

    #Path_2444,
    #Path_2445,
    #Path_2446,
    #Path_2447,
    #Path_2448,
    #Path_2449,
    #Path_2450 {
      fill: #56575E;
    }
  }
}
</style>