<template>
  <svg class="contact-header-svg" width="130" height="130" viewBox="0 0 130 130">
    <g id="Group_1551" data-name="Group 1551" transform="translate(-719 -35)">
      <path id="Path_2048" data-name="Path 2048"
        d="M14.333,27a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,14.333,27Z" transform="translate(708 34.667)"
        fill="#6c63ff" />
      <path id="Path_2049" data-name="Path 2049"
        d="M77.667,12a1.667,1.667,0,1,0,1.667,1.667A1.667,1.667,0,0,0,77.667,12Z" transform="translate(751.333 24.667)"
        fill="#ec615b" />
      <path id="Path_2050" data-name="Path 2050"
        d="M74.667,13a61.667,61.667,0,1,0,61.667,61.667A61.667,61.667,0,0,0,74.667,13Z" transform="translate(709 25)" />
      <path id="Path_2051" data-name="Path 2051"
        d="M85.667,11a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,85.667,11Z" transform="translate(753.333 24)"
        fill="#ec615b" />
      <path id="Path_2052" data-name="Path 2052"
        d="M88.333,22a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,88.333,22Z" transform="translate(757.333 31.333)"
        fill="#6c63ff" />
      <path id="Path_2053" data-name="Path 2053"
        d="M127.667,84A3.333,3.333,0,1,0,131,87.333,3.333,3.333,0,0,0,127.667,84Zm-110-25a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,17.667,59Z"
        transform="translate(708 56)" fill="#ec615b" />
      <path id="Path_2054" data-name="Path 2054"
        d="M26.333,85a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,26.333,85Z" transform="translate(716 73.333)"
        fill="#6c63ff" />
      <path id="Path_2055" data-name="Path 2055"
        d="M20.167,63a4.167,4.167,0,1,0,4.167,4.167A4.167,4.167,0,0,0,20.167,63Zm4.167,25A1.667,1.667,0,1,0,26,89.667,1.667,1.667,0,0,0,24.333,88Zm98.333-55a1.667,1.667,0,1,0,1.667,1.667A1.667,1.667,0,0,0,122.667,33Z"
        transform="translate(711.333 38.667)" fill="#fff" />
      <path id="contact1"
        d="M56.4,11.842H31.2V10.632A3.623,3.623,0,0,0,27.6,7H3.6A3.623,3.623,0,0,0,0,10.632V49.368A3.623,3.623,0,0,0,3.6,53h7.2V49.368h4.8V53H44.4V49.368h4.8V53h7.2A3.623,3.623,0,0,0,60,49.368V15.474A3.623,3.623,0,0,0,56.4,11.842ZM7.2,39.684c.248-4.331,5.981-3.7,6.778-5.949.066-.771.042-1.305.042-2.01a3.961,3.961,0,0,1-1.05-2.331c-.262-.019-.67-.288-.792-1.343a1,1,0,0,1,.347-.984c-.881-3.565-.4-6.677,3.633-6.752a2.252,2.252,0,0,1,2.081.842c2.944.426,2.058,4.573,1.636,5.911a1.01,1.01,0,0,1,.347.984c-.117,1.059-.53,1.324-.792,1.348A4.026,4.026,0,0,1,18.4,31.731c0,.7-.023,1.234.042,2,.8,2.246,6.506,1.617,6.755,5.949Zm45.6,0H31.2V37.263H52.8Zm0-6.053H31.2V31.211H52.8Zm0-6.053H31.2V25.158H52.8Z"
        transform="translate(754 70)" fill="#ef7974" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ContactHeaderIcon"
}
</script>

<style lang="scss" scoped>
:root {
  .contact-header-svg {

    #contact1 {
      fill: #ef7974;
    }

    #Path_2053,
    #Path_2051,
    #Path_2049 {
      fill: #ec615b;
    }

    #Path_2050 {
      fill: #6c63ff;
      opacity: 0.147;
    }
  }
}

:root.dark-theme {
  .contact-header-svg {

    #contact1 {
      fill: #F8BF7B;
    }

    #Path_2053,
    #Path_2051,
    #Path_2049 {
      fill: #F8BF7B;
    }

    #Path_2050 {
      fill: #1e2129;
      opacity: 1;
    }
  }
}
</style>