<template>
  <div class="contact-component">
    <div class="component-nav-icon">
      <!-- <img src="@/assets/icons/contact_header_icon.svg" alt srcset /> -->
      <ContactHeaderIcon />
      <div class="c-n-title">
        <span>CONTACT</span>
        <span>&nbsp;</span>
        <span>ME</span>
      </div>
    </div>

    <div class="cm-c-content">
      <v-layout justify-center>
        <v-card-actions>
          <!-- <img src="@/assets/icons/contactme.svg" alt srcset /> -->
          <ContactMe />
        </v-card-actions>
      </v-layout>
    </div>
  </div>
</template>

<script>
import ContactHeaderIcon from "../SVG/ContactHeaderIcon";
import ContactMe from "../SVG/ContactMe";
export default {
  name: "Contact",
  components: {
    ContactHeaderIcon, ContactMe
  }
}
</script>

<style lang="scss">
:root {
  --contact--cm-c-content-bg: #fff;
}

:root.dark-theme {
  --contact--cm-c-content-bg: #181922;
}

.contact-component {
  .cm-c-content {
    padding: 40px 32px;
    background-color: var(--contact--cm-c-content-bg);

    .v-card__actions img {
      width: 100%;
      height: 100%;
    }

    .v-card__actions svg {
      width: 100%;
      height: 100%;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .contact-component {
    .cm-c-content {
      padding: 70px 32px;
    }
  }
}
</style>
